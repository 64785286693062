import React from 'react';
import { Video } from 'components';
import { RecordedmassQuery } from 'queries/recorded-mass';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const RecordedMass = () => {
  const recordedMass = RecordedmassQuery();
  return (
    <div className="container">
      <div className="thumbnail-wrapper">
        {recordedMass.allStrapiMassRecording.edges.map(item => {
          const { id, title, youtubeUrl, thumbnail } = item.node;
          const imageItem: any =
            thumbnail && thumbnail?.localFile
              ? getImage(thumbnail?.localFile.childImageSharp?.gatsbyImageData)
              : null;
          return (
            <React.Fragment key={id}>
              <div className="thumbnail-card">
                <Video youtubeID={youtubeUrl.split('/').pop()}>
                  {imageItem ? (
                    <div className="thumbnail-card-image youtube">
                      <GatsbyImage image={imageItem} alt={title} />
                      <svg
                        className="thumbnail-card-icon"
                        viewBox="0 0 55 70"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <use href="#youtube" />
                      </svg>
                    </div>
                  ) : (
                    <svg
                      className="thumbnail-card-thumb-icon"
                      viewBox="0 0 80 58"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use href="#youtube" />
                    </svg>
                  )}
                  <span className="thumbnail-card-title">{title}</span>
                  {item?.videoDuration && (
                    <strong className="video-duration">
                      {item.videoDuration}
                    </strong>
                  )}
                </Video>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export { RecordedMass };
