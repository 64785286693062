/* eslint-disable jsx-a11y/anchor-is-valid */
import { ContactForm, PageLayout } from 'components';
import React from 'react';
import Call from 'assets/svg/call.svg';
import Location from 'assets/svg/location.svg';
import { FooterQuery } from 'queries/footer';
import ReactMarkdown from 'react-markdown';

interface ContactSectionProps {
  title: string;
  showContactDetails?: boolean;
  description?: {
    data: {
      description: string;
    };
  };
  signUp?: [
    {
      title: string;
      value: string;
    }
  ];
}

const ContactSection = ({
  title,
  showContactDetails,
  description,
  signUp,
}: ContactSectionProps) => {
  const footerDetails = FooterQuery();
  return (
    <PageLayout title={title}>
      <div className="container contact-section-layout">
        <div className="contact-section-content">
          <h2>{title}</h2>
          <div className="contact-section-content-wrapper">
            {showContactDetails && (
              <>
                <div className="contact-section-cta">
                  <span className="contact-section-cta-title">Phone</span>
                  <div className="contact-section-phone">
                    <a
                      href={`tel:${footerDetails.strapiGlobal.footer.phoneNumber}`}
                    >
                      <Call />{' '}
                      <span>{footerDetails.strapiGlobal.footer.phoneText}</span>
                    </a>
                  </div>
                </div>
                <div className="contact-section-cta">
                  <span className="contact-section-cta-title">Address</span>
                  <div className="contact-section-address">
                    <Location />
                    <span>
                      {footerDetails.strapiGlobal.footer.address.data.address}
                    </span>
                  </div>
                </div>
              </>
            )}
            {description?.data?.description && (
              <ReactMarkdown>{description.data.description}</ReactMarkdown>
            )}
          </div>
        </div>
        <ContactForm signUp={signUp} />
      </div>
    </PageLayout>
  );
};

ContactSection.defaultProps = {
  showContactDetails: false,
  description: null,
  signUp: null,
};

export { ContactSection };
