/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Video } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

const Wrapper = ({ type, children }: any) => {
  if (type === 'list') {
    return (
      <div className="list-container">
        <ul className="list">{children}</ul>
      </div>
    );
  }
  return <div className="thumbnail-wrapper">{children}</div>;
};

const Element = ({ type, children }: any) => {
  if (type === 'list') {
    return <li className="list-card">{children}</li>;
  }
  return <div className="thumbnail-card">{children}</div>;
};

const LinkElement = ({ link, children, ...props }: any) => {
  if (link) {
    return (
      <div className="pdf-link">
        <svg
          className="thumbnail-card-icon"
          viewBox="0 0 55 70"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use href="#pdf" fill="red" />
        </svg>
        <a href={link} target="_blank" rel="noreferrer" {...props}>
          {children}
        </a>
      </div>
    );
  }
  return <React.Fragment {...props}>{children}</React.Fragment>;
};

const VideoWrapper = ({ item, imageItem, children, ...props }: any) => {
  if (item.youtubeUrl !== null || '' || item.video) {
    return (
      <div className="thumbnail-card">
        <Video video={item.video} youtubeID={item.youtubeUrl.split('/').pop()}>
          {imageItem ? (
            <div className="thumbnail-card-image youtube">
              <GatsbyImage image={imageItem} alt={item.title} />
              <svg
                className="thumbnail-card-icon"
                viewBox="0 0 55 70"
                xmlns="http://www.w3.org/2000/svg"
              >
                <use href="#youtube" />
              </svg>
            </div>
          ) : (
            <svg
              className="thumbnail-card-thumb-icon"
              viewBox="0 0 80 58"
              xmlns="http://www.w3.org/2000/svg"
            >
              <use href="#youtube" />
            </svg>
          )}
          <div className="thumbnail-card-icon-title">
            <span className="thumbnail-card-title">{item.title}</span>

            {item?.videoDuration && (
              <strong className="video-duration">{item.videoDuration}</strong>
            )}
          </div>
        </Video>
      </div>
    );
  }
  return <React.Fragment {...props}>{children}</React.Fragment>;
};

const ListItem = ({ item, type }: any) => {
  const imageItem: any =
    item?.image && item?.image?.localFile
      ? getImage(item?.image?.localFile.childImageSharp?.gatsbyImageData)
      : null;
  if (type === 'profile' || type === 'QRcode') {
    return (
      <div className="thumbnail-card" key={item.title}>
        <div className={`thumbnail-card-image ${type || ''}`}>
          <GatsbyImage
            image={imageItem}
            alt={item?.image?.alternativeText ?? (item.title || '')}
          />
        </div>
        {item.title && <div className="thumbnail-card-title">{item.title}</div>}
      </div>
    );
  }
  if (type === 'youtube') {
    return <VideoWrapper item={item} imageItem={imageItem} />;
  }
  return (
    <Element type={type} key={item.title}>
      <LinkElement link={item.file?.localFile?.publicURL || item.pdfLink}>
        <VideoWrapper item={item} image={imageItem}>
          {imageItem && (
            <div className={` ${type || ''}`}>
              <GatsbyImage image={imageItem} alt={item.title} />
            </div>
          )}
          <div className="">{item.title}</div>
        </VideoWrapper>
      </LinkElement>
    </Element>
  );
};

const ImageVideoPDFSection = ({ sectionData, title, type }: any) => {
  return (
    <div className="container">
      <div className="heading-design">
        <h3 className="section-title">
          <ReactMarkdown>{title}</ReactMarkdown>
        </h3>
      </div>
      <Wrapper type={type}>
        {sectionData &&
          sectionData.map((item: any) => (
            <ListItem item={item} type={type} key={item.title} />
          ))}
      </Wrapper>
    </div>
  );
};

export { ImageVideoPDFSection };
