import moment from 'moment';

export const truncate = (str: string, max: number) => {
  const suffix = '...';
  return str.length < max
    ? str
    : `${str.substr(
        0,
        str.substr(0, max - suffix.length).lastIndexOf(' ')
      )}${suffix}`;
};

export const getClosestDate = (sortedDates: any) => {
  let closestIndex = 0;
  let closestDiff = 50;

  sortedDates.forEach((date: any, index: number) => {
    const momentDate = moment(date.date);
    const dateDiff = Math.abs(moment().diff(momentDate, 'days'));
    if (dateDiff < closestDiff) {
      closestDiff = dateDiff;
      closestIndex = index;
    }
  });
  return closestIndex;
};

type Mass = {
  time: string;
  title: string;
  id?: string;
};

type MassSchedule = {
  Sat: Mass[];
  Sun: Mass[];
  Mon?: Mass[];
  Tue?: Mass[];
  Wed?: Mass[];
  Thu?: Mass[];
  Fri?: Mass[];
};

export interface CalendarDateType {
  date: string;
  events: Mass[];
  id?: string;
}

const weeklyEvents: MassSchedule = {
  Sat: [
    { time: '9:15 am', title: 'Morning Mass' },
    { time: '6:00 pm', title: 'Weekend Mass' },
  ],
  Sun: [{ time: '9:30 am', title: 'Sunday Mass' }],
  Tue: [{ time: '9:15 am', title: 'Weekday Mass' }],
  Wed: [{ time: '9:15 am', title: 'Weekday Mass' }],
  Thu: [{ time: '9:15 am', title: 'Weekday Mass' }],
  Fri: [{ time: '9:15 am', title: 'Weekday Mass' }],
};

export const getScheduledEvents = () => {
  const today = new Date();
  const currentDate = new Date();
  currentDate.setDate(today.getDate() - 30);
  const scheduledEvents: CalendarDateType[] = [];
  for (let i = 0; i < 60; i += 1) {
    const currentDay = currentDate.toDateString().substring(0, 3);
    const eventsForDay = weeklyEvents[currentDay as keyof typeof weeklyEvents];
    if (eventsForDay) {
      scheduledEvents.push({
        date: currentDate.toDateString(),
        events: eventsForDay,
      });
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }
  return scheduledEvents;
};

export const mergeEvents = (
  eventsA: CalendarDateType[],
  eventsB: CalendarDateType[]
) => {
  const resultArray = Object.assign(eventsA, []);
  eventsB.forEach((eventB: CalendarDateType) => {
    if (
      !resultArray.find(
        (event: CalendarDateType) =>
          moment(event.date).format() === moment(eventB.date).format()
      )
    ) {
      resultArray.push(eventB);
    }
  });
  return resultArray;
};
