/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import { Seo, metaDescriptionProp } from 'components';

export interface PageLayoutProps {
  children: React.ReactNode;
  title: string;
  description?: metaDescriptionProp;
  image?: any;
  metaTitle?: string;
}

export const PageLayout: FC<PageLayoutProps> = ({
  children,
  title,
  description,
  image,
  metaTitle,
}) => {
  return (
    <>
      <Seo title={metaTitle ?? title} description={description} image={image} />
      {children}
    </>
  );
};
