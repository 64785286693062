/* eslint-disable no-shadow */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const StaffDetail = ({
  Heading,
  description,
  name,
  phoneNo,
  role,
  mailId,
  faxNo,
  image,
  parishDetails,
}: any) => {
  const staffDetailImage: any =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;
  return (
    <div className="staff-detail">
      <div className="staff-detail-wrapper">
        <h2 className="staff-detail-title">
          <ReactMarkdown>{Heading}</ReactMarkdown>
        </h2>
        {description && <ReactMarkdown>{description}</ReactMarkdown>}
        {staffDetailImage ? (
          <div className="staff-detail-img">
            <GatsbyImage image={staffDetailImage} alt={name || ''} />
          </div>
        ) : null}
        {name && <div className="staff-detail-name">{name}</div>}
        {role && <div className="staff-detail-designation">{role}</div>}
        <div className="staff-detail-mail">
          {mailId && (
            <>
              <span>Email: </span>
              {mailId}
            </>
          )}
        </div>
        <div className="staff-detail-contact">
          {phoneNo ? (
            <div className="staff-detail-contact-title">
              <span>Contact Us: </span>
              <a className="phone-link" href={`tel:${phoneNo}`}>
                {phoneNo}
              </a>
            </div>
          ) : null}

          {faxNo && (
            <div className="staff-detail-fax">
              <span>Fax: </span>
              {faxNo}
            </div>
          )}
        </div>
      </div>
      <div
        className={`staff-detail-address ${
          parishDetails.length === 1 ? 'center-text' : ''
        }`}
      >
        {parishDetails.map((item: any) => {
          const { title, summary } = item;
          return (
            <div className="staff-detail-address-wrapper" key={title}>
              {title && (
                <div className="staff-detail-address-title">{title}</div>
              )}
              <div className="staff-detail-address-summary">
                <ReactMarkdown>{summary.data.summary}</ReactMarkdown>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
