import { useStaticQuery, graphql } from 'gatsby';

const FooterQuery = () => {
  return useStaticQuery(graphql`
    query Footer {
      strapiGlobal {
        footer {
          contactMail
          contactPerson
          contactPersonTitle
          contactUsTitle
          copyrightMessae
          officeHoursTitle
          officeWorkingDays
          officeWorkingTime
          phoneNumber
          phoneText
          websiteTagline
          websiteTaglineLink
          footerMenuTitle
          address {
            data {
              address
            }
          }
          footerContent {
            data {
              footerContent
            }
          }
          footerLinks {
            title
            link
          }
        }
        subscribe {
          title
          tagline
          showSubscribeForm
        }
        socialMediaLinks {
          facebookLink
          instagramLink
          twitterLink
          youtubeLink
        }
      }
    }
  `);
};

export { FooterQuery };
