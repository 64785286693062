/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react';
import {
  AllDynamicComponent,
  ReconciliationChildForm,
  ReconciliationParentForm,
  ModalComponent,
} from 'components';
import Arrow from 'assets/svg/nextarrow.svg';
import PrevArrow from 'assets/svg/prevarrow.svg';
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';
import { useForm } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
// @ts-ignore
import app from 'gatsby-plugin-firebase-v9.0';
import Loader from 'assets/svg/loader.svg';

const Form = ({
  location,
  isModal,
  setisModal,
  modelContent,
  formTypes,
}: any) => {
  const formContent = modelContent[0]?.node.formContent.data.formContent;
  const formTitle = modelContent[0]?.node.formTitle.data.formTitle;
  const formSummary = modelContent[0]?.node.formSummary.data.formSummary;
  const captchaInfoTitle = modelContent[0]?.node.captchaInfo.data.captchaInfo;
  const agreementData = modelContent[0]?.node?.dynamicComponents.find(item => {
    return item.title === '**Our** Agreements';
  });
  const agreementEmailData = agreementData?.summary.data.summary;
  const childTitle = [
    {
      title: 'Child 1 Details',
      fields: {
        childfirstname: 'child1firstname',
        childsurname: 'child1surname',
        childdob: 'child1dob',
        childnameofschool: 'child1nameofschool',
        childgrade: 'child1grade',
        childbaptisedat: 'child1baptisedat',
        childbaptisedon: 'child1baptisedon',
        required: true,
      },
    },
    {
      title: 'Child 2 Details',
      fields: {
        childfirstname: 'child2firstname',
        childsurname: 'child2surname',
        childdob: 'child2dob',
        childnameofschool: 'child2nameofschool',
        childgrade: 'child2grade',
        childbaptisedat: 'child2baptisedat',
        childbaptisedon: 'child2baptisedon',
        required: false,
      },
    },
  ];
  const parentTitle = [
    {
      title: 'Parent 1 Details',
      fields: {
        parentfirstname: 'parent1firstname',
        parentsurname: 'parent1surname',
        parentreligion: 'parent1religion',
        parentresidentialAddress: 'parent1residentialAddress',
        parentlandline: 'parent1landline',
        parentmobile: 'parent1mobile',
        parentemail: 'parent1email',
        required: true,
      },
    },
    {
      title: 'Parent 2 Details',
      fields: {
        parentfirstname: 'parent2firstname',
        parentsurname: 'parent2surname',
        parentreligion: 'parent2religion',
        parentresidentialAddress: 'parent2residentialAddress',
        parentlandline: 'parent2landline',
        parentmobile: 'parent2mobile',
        parentemail: 'parent2email',
        required: false,
      },
    },
  ];
  const [loading, isloading] = useState(false);
  const [active, setActive] = useState(childTitle[0].title);
  const [showMessage, setShowMessage] = useState(false);
  const [showBtn, setShowBtn] = useState(false);

  const handleToggle = (index: any) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isValid },
  } = useForm();

  const handleModalClose = () => {
    setisModal(false);
    setShowMessage(false);
    setShowBtn(false);
    const path = location.href.split('/').slice(0, -1).join('/');
    window.history.pushState('', '', `${path}`);
    reset();
  };

  const onSubmit = (data: any) => {
    const formDataList: any[] = [];
    const childFormData = [
      {
        title: 'Child 1 Details',
        firstName: data.child1firstname,
        surName: data.child1surname,
        dob: data.child1dob,
        nameofschool: data.child1nameofschool,
        grade: data.child1grade,
        baptisedat: data.child1baptisedat,
        baptisedon: data.child1baptisedon,
      },
      {
        title: 'Child 2 Details',
        firstName: data.child2firstname,
        surName: data.child2surname,
        dob: data.child2dob,
        nameofschool: data.child2nameofschool,
        grade: data.child2grade,
        baptisedat: data.child2baptisedat,
        baptisedon: data.child2baptisedon,
      },
    ];
    const parentFormData = [
      {
        title: 'Parent 1 Details',
        firstName: data.parent1firstname,
        surName: data.parent1surname,
        religion: data.parent1religion,
        residentialAddress: data.parent1residentialAddress,
        landlineNo: data.parent1landline,
        mobileNo: data.parent1mobile,
        email: data.parent1email,
      },
      {
        title: 'Parent 2 Details',
        firstName: data.parent2firstname,
        surName: data.parent2surname,
        religion: data.parent2religion,
        residentialAddress: data.parent2residentialAddress,
        landlineNo: data.parent2landline,
        mobileNo: data.parent2mobile,
        email: data.parent2email,
      },
    ];
    formDataList.push({
      childForm: childFormData,
      parentForm: parentFormData,
      parishMinistry: data.parishMinistry,
      email: data.email,
      agreementEmailData: agreementEmailData.replace(/\n/g, '<br />'),
      formType: formTypes,
    });
    const functions = getFunctions(app, 'australia-southeast1');
    if (process.env.NODE_ENV === 'development')
      connectFunctionsEmulator(functions, 'localhost', 5001);
    const handleRegisterForm = httpsCallable(functions, 'handleRegisterForm');
    if (isValid) {
      isloading(true);
      handleRegisterForm(formDataList[0])
        .then(() => {
          setError('submit', {
            type: 'server',
            message: 'Form submitted succesfully!',
          });
          setShowMessage(true);
          isloading(false);
        })
        .catch(() => {
          setError('submit', {
            type: 'server',
            message: 'Something went wrong with submission! Try again',
          });
          setShowBtn(true);
          setShowMessage(true);
          isloading(false);
        });
    }
  };
  return (
    <div>
      <ModalComponent
        isModal={isModal}
        closesModal={handleModalClose}
        className="form-modal"
      >
        {!showMessage && (
          <div>
            <div className="page-heading">
              <div className="heading-design">
                <h3 className="section-title">
                  <ReactMarkdown>{formTitle}</ReactMarkdown>
                </h3>
              </div>
              {formSummary && !showMessage && (
                <div className="page-content">
                  <ReactMarkdown>{formSummary}</ReactMarkdown>
                </div>
              )}
            </div>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              {childTitle.map(item => {
                return (
                  <div
                    className={`accordion-container ${
                      active === item.title ? 'active' : ''
                    } ${
                      Object.keys(errors).length > 0 &&
                      item.fields.required === true &&
                      'active'
                    }`}
                    key={item.title}
                  >
                    <button
                      type="button"
                      className="form-accordion-title"
                      onClick={() => handleToggle(item.title)}
                    >
                      <p>{item.title}</p>
                      <p>
                        <Arrow />
                      </p>
                    </button>
                    <ReconciliationChildForm
                      register={register}
                      errors={errors}
                      fields={item.fields}
                      formContent={formContent}
                    />
                  </div>
                );
              })}
              {parentTitle.map(item => {
                return (
                  <div
                    className={`accordion-container ${
                      active === item.title ? 'active' : ''
                    } ${
                      Object.keys(errors).length > 0 &&
                      item.fields.required === true &&
                      'active'
                    }`}
                    key={item.title}
                  >
                    <button
                      type="button"
                      className="form-accordion-title"
                      onClick={() => handleToggle(item.title)}
                    >
                      <p>{item.title}</p>
                      <p>
                        <Arrow />
                      </p>
                    </button>
                    <ReconciliationParentForm
                      register={register}
                      errors={errors}
                      fields={item.fields}
                    />
                  </div>
                );
              })}
              <AllDynamicComponent
                alldynamicComponents={modelContent[0]?.node?.dynamicComponents}
                formregister={register}
                formerrors={errors}
              />
              <div className="Parish-checklist-container-list">
                <div className="Parish-checklist-container-list-item">
                  <label
                    htmlFor="checkbox"
                    className="Parish-checklist-container-list-item-label"
                  >
                    <input
                      {...register('agree', {
                        required: {
                          value: true,
                          message: 'Please select this field.',
                        },
                      })}
                      type="checkbox"
                    />
                    <span>Agree</span>
                  </label>
                </div>

                {errors.agree && (
                  <div className="error-message">
                    <p>{errors.agree.message}</p>
                  </div>
                )}
              </div>
              <div className="page-heading">
                <div className="page-content center">
                  <p>
                    Kindly enter an email address if you wish for a copy of the
                    form you have submitted.
                  </p>
                </div>
              </div>
              <div className="form-field email-field">
                <label htmlFor="email">
                  Email Address <span className="required">*</span>
                  <input
                    type="text"
                    className="text-field"
                    {...register('email', {
                      required: {
                        value: true,
                        message: 'Email is required',
                      },
                      pattern: {
                        value:
                          /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/g,
                        message: 'Invalid Email',
                      },
                    })}
                  />
                  {errors.email && (
                    <div className="error-message">{errors.email.message}</div>
                  )}
                </label>
              </div>
              <div className="form-cta">
                <button
                  type="submit"
                  className="form-cta-button"
                  {...register('submit')}
                >
                  {loading && (
                    <span className="form-action-loader">
                      <Loader />
                    </span>
                  )}
                  {!loading && <span> Submit </span>}
                </button>
              </div>
              <div className="page-content center">
                <ReactMarkdown>{captchaInfoTitle}</ReactMarkdown>
              </div>
            </form>
          </div>
        )}
        {showMessage && (
          <div className="form-submit-message">
            <div className="page-heading">
              <div className="heading-design">
                <h3 className="section-title">
                  <ReactMarkdown>{formTitle}</ReactMarkdown>
                </h3>
              </div>
            </div>
            {showBtn && (
              <button
                type="button"
                onClick={() => {
                  setShowMessage(false);
                  setShowBtn(false);
                }}
                className="form-submit-message-back-btn"
              >
                <PrevArrow />
                <span>back</span>
              </button>
            )}
            {errors.submit && (
              <p className="form-submit-message-text">
                {errors.submit.message}
              </p>
            )}
          </div>
        )}
      </ModalComponent>
    </div>
  );
};

export { Form };
