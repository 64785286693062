/* eslint-disable no-shadow */
import { Link } from 'gatsby';
import React, { useEffect, useState, useRef, FC } from 'react';
import Arrow from 'assets/svg/nextarrow.svg';
import SubmenuArrow from 'assets/svg/menu-arrow.svg';
import ArrowBg from 'assets/svg/arrow-bg.svg';
import { useScrollDirection } from 'hooks/useScrollDirection';

interface Submenu {
  slug: String;
  strapiChildren: any;
  title: String;
}
interface SubmenuProps {
  submenu: Array<Submenu>;
  location: String;
  SectionRefs: React.RefObject<HTMLElement>[];
  matchPath: String;
}

export const SubMenu: FC<SubmenuProps> = ({
  subMenu,
  location,
  SectionRefs,
  matchPath,
}: any) => {
  const submenuRef = useRef(null);
  const [activeTab, setActiveTab] = useState<number | null>();
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const scrollDirection = useScrollDirection();
  const [isMobile, setIsMobile] = useState(false);
  const [submenuHeight, setSubmenuHeight] = useState<number>(200);
  const [headerHeight, setHeaderHeight] = useState(0);
  const TabRefs = useRef<React.RefObject<HTMLElement>[]>([]);
  const addTabRefs = (el: any) => {
    if (el && !TabRefs.current.includes(el as never)) {
      TabRefs.current.push(el);
    }
  };
  const observerOptions = {
    rootMargin: '0px',
    threshold: 0.1,
  };

  function observerCallback(entries: any) {
    entries.forEach((entry: any) => {
      if (entry.isIntersecting) {
        window.history.replaceState(
          {},
          '',
          `${location}/${subMenu[entry.target.elIndex].slug}`
        );
        setActiveTab(entry.target.elIndex);
      }
    });
  }
  const scrollToElement = (index: number, slug: string) => {
    let offset;
    if (isMobile) {
      offset = 20;
    } else {
      offset = 40 + submenuHeight;
    }
    if (activeTab === index) {
      setActiveTab(null);
    } else {
      setActiveTab(index);
    }
    window.history.replaceState({}, '', `${location}/${slug}`);
    if (SectionRefs[index]) {
      const elementPosition = SectionRefs[index].getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };
  const closeOpenMenu = e => {
    if (
      submenuRef.current &&
      isSubmenuOpen &&
      !submenuRef.current.contains(e.target)
    ) {
      setIsSubmenuOpen(false);
    }
  };

  const debounce = (callback: any, wait: number) => {
    let timeoutId: any = null;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        callback.apply(null, args);
      }, wait);
    };
  };

  useEffect(() => {
    if (window.scrollY < 150) {
      setHeaderHeight(document.getElementsByTagName('header')[0].offsetHeight);
    }
    if (isMobile) {
      document.body.classList.toggle('sub-menu-open', isSubmenuOpen);
    }
    document.addEventListener('mousedown', closeOpenMenu);
    return () => {
      document.body.classList.remove('sub-menu-open');
    };
  }, [isSubmenuOpen]);

  useEffect(() => {
    setSubmenuHeight(submenuRef.current.offsetHeight);
    setIsMobile(window.innerWidth < 992);
    const observer = new IntersectionObserver(
      debounce(observerCallback, 400),
      observerOptions
    );
    subMenu.map((item: any, index: number) => {
      if (matchPath === item.slug) {
        scrollToElement(index, item.slug);
      }
    });
    SectionRefs.forEach((el: any, index: number) => {
      if (el) {
        el.elIndex = index;
        observer.observe(el);
      }
    });
  }, []);
  useEffect(() => {
    const header = document.getElementsByTagName('header')[0];
    const position = window
      .getComputedStyle(header)
      .getPropertyValue('position');
    if (position === 'sticky') {
      setHeaderHeight(document.getElementsByTagName('header')[0].offsetHeight);
    } else {
      setHeaderHeight(0);
    }
  }, [scrollDirection]);

  return (
    <div
      className={`sub-menu ${isSubmenuOpen ? 'active' : ''}`}
      ref={submenuRef}
      style={{ top: headerHeight }}
    >
      <div
        className={`sub-menu-container ${
          isSubmenuOpen ? 'sub-menu-active' : ''
        } `}
        ref={submenuRef}
      >
        {subMenu.map((item: any, index: number) => {
          const { slug, title } = item;
          const strapiCildren = item.strapi_children;
          return (
            <div
              key={slug}
              className={`
                ${
                  activeTab === index
                    ? 'sub-menu-container-item active'
                    : 'sub-menu-container-item'
                }
              `}
              ref={addTabRefs}
            >
              <button
                type="button"
                className="sub-menu-container-item-btn"
                onClick={() => scrollToElement(index, slug)}
              >
                {title} {strapiCildren.length > 0 ? <Arrow /> : null}
              </button>
              {strapiCildren && strapiCildren.length > 0 && (
                <div className="sub-menu-container-item-child-container">
                  {strapiCildren &&
                    strapiCildren.length > 0 &&
                    strapiCildren.map((child: any) => {
                      const { title } = child;
                      const pageslug = child.slug;
                      return (
                        <Link
                          to={`/${slug}/${pageslug}`}
                          className="sub-menu-container-item-child"
                          key={title}
                        >
                          {title}
                        </Link>
                      );
                    })}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {subMenu.length > 0 && (
        <button
          type="button"
          className={`open-sub-menu ${isMobile && isSubmenuOpen ? 'hide' : ''}`}
          onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}
        >
          <div className="arrow-wrapper">
            <ArrowBg className="open-sub-menu-btn" />
            <SubmenuArrow className="open-sub-menu-arrow" />
          </div>
        </button>
      )}
    </div>
  );
};
