import { useMemo } from 'react';

const range = (start: any, end: any) => {
  const length = end - start;
  return Array.from({ length }, (_, idx) => idx + start);
};

interface UsePrevNextButtonTypes {
  totalCount: number;
  pageSize: number;
  currentPage: number;
}

export const usePrevNextButton = ({
  totalCount,
  pageSize,
  currentPage,
}: UsePrevNextButtonTypes) => {
  const prevNextButtonRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    return range(1, totalPageCount);
  }, [totalCount, pageSize, currentPage]);

  return prevNextButtonRange;
};
