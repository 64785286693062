import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { GatsbyImage } from 'gatsby-plugin-image';

const HomePageSlider = ({ SliderData }: any) => {
  return (
    <div className="homepage-slider">
      <Swiper
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
        }}
        slidesPerView={1}
        speed={1200}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 50000,
          disableOnInteraction: false,
        }}
        loop
        className="homepage-slider-swiper"
      >
        {SliderData.map((item: any) => {
          const { id, title, tagline, image } = item;
          return (
            <SwiperSlide key={id}>
              <div className="homepage-slider-wrapper" key={title}>
                <div className="homepage-slider-image">
                  <GatsbyImage
                    image={image?.localFile?.childImageSharp?.gatsbyImageData}
                    alt={title}
                  />
                </div>
                <div className="homepage-slider-content">
                  <h3>{title}</h3>
                  <p className="homepage-slider-description">{tagline}</p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
        <div className="swiper-pagination" />
      </Swiper>
    </div>
  );
};

export { HomePageSlider };
