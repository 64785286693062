/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import Helmet from 'react-helmet';
import { withPrefix, Link } from 'gatsby';
import Facebook from 'assets/svg/facebook-icon.svg';
import Instagram from 'assets/svg/instagram-icon.svg';
import Twitter from 'assets/svg/twitter-icon.svg';
import Youtube from 'assets/svg/youtube-icon.svg';
import Call from 'assets/svg/call.svg';
import Calendar from 'assets/svg/calendar.svg';
import Clock from 'assets/svg/clock.svg';
import Mail from 'assets/svg/sms.svg';
import User from 'assets/svg/person.svg';
import { ScrollTop } from 'components';
import Location from 'assets/svg/location.svg';
import Logo from 'assets/svg/footer-logo.svg';
import Arrow from 'assets/svg/submit.svg';
import ReactMarkdown from 'react-markdown';

export const Footer = ({ footer, socialMediaLinks, subscribe }: any) => {
  const {
    contactMail,
    copyrightMessae,
    officeWorkingDays,
    officeWorkingTime,
    phoneNumber,
    phoneText,
    address,
    footerMenuTitle,
    footerLinks,
    footerContent,
  } = footer;
  return (
    <footer className="footer">
      <Helmet>
        <script
          src={withPrefix('klaviyo-subscribe.js')}
          type="text/javascript"
        />
      </Helmet>
      <div className="container">
        <div className="footer-wrapper">
          <div className="footer-address">
            <div className="footer-logo">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="footer-social-icons">
              {socialMediaLinks && socialMediaLinks.instagramLink ? (
                <Link target="_blank" to={socialMediaLinks.instagramLink}>
                  <Instagram />
                </Link>
              ) : null}
              {socialMediaLinks && socialMediaLinks.youtubeLink ? (
                <Link target="_blank" to={socialMediaLinks.youtubeLink}>
                  <Youtube />
                </Link>
              ) : null}
              {socialMediaLinks && socialMediaLinks.facebookLink ? (
                <Link target="_blank" to={socialMediaLinks.facebookLink}>
                  <Facebook />
                </Link>
              ) : null}
              {socialMediaLinks && socialMediaLinks.twitterLink ? (
                <Link target="_blank" to={socialMediaLinks.twitterLink}>
                  <Twitter />
                </Link>
              ) : null}
            </div>
            <div className="footer-details">
              <div className="footer-details-items">
                <address>
                  <p className="footer-details-content">
                    <span>
                      <Location />
                    </span>
                    {address.data.address}
                  </p>
                </address>
              </div>
              <div className="footer-details-items">
                <p className="footer-details-content">
                  <span>
                    <Calendar />
                  </span>
                  {officeWorkingDays}
                </p>
                <p className="footer-details-content number-font" />
              </div>
              <div className="footer-details-items">
                <p className="footer-details-content">
                  <span className="footer-clock">
                    <Clock />
                  </span>
                  {officeWorkingTime}
                </p>
              </div>
              <div className="footer-details-items">
                <p className="footer-details-content">
                  <span>
                    <Call />
                  </span>
                  <a href={`tel:${phoneNumber}`}>{phoneText}</a>
                </p>
              </div>
              <div className="footer-details-items">
                <p className="footer-details-content">
                  <span>
                    <Mail />
                  </span>
                  <a href={`mailto:${contactMail}`}>{contactMail}</a>
                </p>
              </div>
            </div>
          </div>
          <div
            className={`footer-wrapper-content ${
              !subscribe?.showSubscribeForm ? 'form-hidden' : ''
            }`}
          >
            {subscribe?.showSubscribeForm && (
              <div className="footer-subscribe">
                <div className="footer-subscribe-content">
                  <div className="footer-subscribe-title">
                    {subscribe.title}
                  </div>
                  <div className="footer-subscribe-tagline">
                    {subscribe.tagline}
                  </div>
                </div>
                <form
                  className="footer-subscribe-form"
                  id="klaviyo_subscribe"
                  action="//manage.kmail-lists.com/subscriptions/subscribe"
                  data-ajax-submit="//manage.kmail-lists.com/ajax/subscriptions/subscribe"
                  method="GET"
                  target="_blank"
                >
                  <input type="hidden" name="g" value="WCYTcN" />
                  <input type="hidden" name="$source" value="Source" />
                  <input type="hidden" name="$fields" value="$name" />
                  <div className="klaviyo_field_group klaviyo_form_actions">
                    <div className="form-field">
                      <span className="after">
                        <User />
                      </span>
                      <input
                        type="text"
                        name="$name"
                        placeholder="Name"
                        className="text-field"
                        required
                      />
                    </div>
                    <div className="form-field">
                      <span className="after">
                        <Mail />
                      </span>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="text-field"
                        required
                      />
                      <div className="klaviyo_form_actions_button">
                        <button type="submit" className="submit-button">
                          <Arrow />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="success_message"
                    style={{ display: 'none' }}
                  />
                </form>
              </div>
            )}

            <div className="footer-menu">
              <div className="footer-menu-title">{footerMenuTitle}</div>
              <ul className="footer-menu-list">
                {footerLinks.map((item: any) => (
                  <li className="menu-list-item" key={item.link}>
                    <Link to={`/${item.link}`} className="menu-list-item-link">
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer-content">
              <ReactMarkdown>{footerContent.data.footerContent}</ReactMarkdown>
            </div>
          </div>
        </div>

        <div className="footer-copyright">
          {copyrightMessae}
          <p className="footer-tag">
            Designed and built by{' '}
            <a href="https://ozzi.tech" target="_blank">
              Ozzitech
            </a>
          </p>
        </div>
      </div>
      <ScrollTop />
    </footer>
  );
};
