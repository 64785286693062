import React from 'react';
import ReactMarkdown from 'react-markdown';
import LeftLeaf from 'assets/svg/left-leaf.svg';
import RightLeaf from 'assets/svg/right-leaf.svg';

export const PageHeading = ({ content, subHeading, Heading, active }: any) => {
  if (typeof active === 'boolean' && !active) {
    return null;
  }
  return (
    <div>
      <div className="page-heading">
        <div className="heading-design">
          <LeftLeaf />
          <h2 className="page-title">
            <ReactMarkdown>{Heading}</ReactMarkdown>
          </h2>
          <RightLeaf />
        </div>
        {subHeading && <div className="sub-heading">{subHeading}</div>}
        {content && (
          <div className="page-content">
            <ReactMarkdown>{content}</ReactMarkdown>
          </div>
        )}
      </div>
    </div>
  );
};
