import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { CTALink } from 'components';
import Arrow from 'assets/svg/arrow-right.svg';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';

const HomeArticles = ({ blogSlider, heading, ctaTitle, ctaLink }: any) => {
  return (
    <div className="container">
      <div className="blogs-wrapper">
        <div className="blogs-wrapper-section">
          <h2 className="blogs-wrapper-heading">{heading}</h2>
          <Link to={ctaLink} className="blogs-link desktop">
            {ctaTitle} <Arrow />
          </Link>
        </div>

        <div className="blogs-wrapper-content">
          {blogSlider &&
            blogSlider.length > 0 &&
            blogSlider.map((item: any) => {
              if (item.articles.length === 0) {
                return null;
              }
              const { title, date, description, image, slug } =
                item.articles[0];
              const articledate = moment(date).format('MMM DD, YYYY');
              const Image: any =
                image && image && image.localFile
                  ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
                  : null;
              return (
                <div className="blogs-wrapper-content-wrapper">
                  <CTALink ctaLink={`/blogs/${slug}`}>
                    {Image && (
                      <GatsbyImage image={Image} alt={image[0]?.name} />
                    )}
                    {title && (
                      <h2 className="blogs-wrapper-content-title">{title}</h2>
                    )}
                  </CTALink>
                  {description && (
                    <div className="blogs-wrapper-content-description">
                      <ReactMarkdown>
                        {description.data.description}
                      </ReactMarkdown>
                    </div>
                  )}
                  {date && (
                    <p className="blogs-wrapper-content-date">{articledate}</p>
                  )}
                </div>
              );
            })}
        </div>
        <Link to={ctaLink} className="blogs-link mobile">
          {ctaTitle} <Arrow />
        </Link>
      </div>
    </div>
  );
};

export { HomeArticles };
