import React, { useState } from 'react';
import { VideoModal } from 'components';

const Video = ({ image, video, youtubeID, children }: any) => {
  const [isModalOpen, setisModalOpen] = useState(false);
  const handleModalOpen = () => {
    setisModalOpen(true);
  };

  return (
    <div>
      <button
        className="video-button"
        type="button"
        onClick={() => {
          handleModalOpen();
        }}
      >
        {children}
      </button>
      <VideoModal
        isModalOpen={isModalOpen}
        setisModalOpen={setisModalOpen}
        modalPosterImage={image}
        modalVideo={video}
        modalYoutubeID={youtubeID}
      />
    </div>
  );
};
export { Video };
