/* eslint-disable no-redeclare */
import React, { FC } from 'react';

interface MassTimesCardProps {
  massTimeData: {
    dayTitle: String;
    timeTitle: String;
    SundayMassTimings: {
      day: String;
      time: String;
    }[];
    DailyMassTimings: {
      day: String;
      time: String;
      note: String;
    }[];
  };
}

const MassTimesCard: FC<MassTimesCardProps> = ({ massTimeData }) => {
  return (
    <div className="card-schedule-wrapper">
      <div className="card-content">
        <div className="heading-design">
          <h3>
            <strong>Sunday</strong> Mass
          </h3>
        </div>
        <div className="card-content-detail">
          <div className="card-content-subheading">
            <h5 className="card-content-subheading-date">
              {massTimeData.dayTitle}
            </h5>
            <h5 className="card-content-subheading-time">
              {massTimeData.timeTitle}
            </h5>
          </div>
          {massTimeData.SundayMassTimings.map((item: any) => {
            const { day, time } = item;
            return (
              <div className="card-content-wrapper" key={day}>
                <div className="card-content-wrapper-day">{day}</div>
                <div className="card-content-wrapper-daytime">{time}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="card-content">
        <div className="heading-design">
          <h3>
            <strong>Daily</strong> Mass
          </h3>
        </div>
        <div className="card-content-detail">
          <div className="card-content-subheading">
            <h5 className="card-content-subheading-date">
              {massTimeData.dayTitle}
            </h5>
            <h5 className="card-content-subheading-time">
              {massTimeData.timeTitle}
            </h5>
          </div>
          {massTimeData.DailyMassTimings.map((item: any) => {
            const { id, day, time, note } = item;
            return (
              <div key={id} className="card-content-wrapper">
                <div className="card-content-wrapper-day">{day}</div>
                <div className="card-content-wrapper-daytime">
                  {time} {` `}
                  <span>{note}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { MassTimesCard };
