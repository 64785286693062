import React from 'react';
import Quotesymbol from 'assets/svg/quote.svg';
import ReactMarkdown from 'react-markdown';

const PrayerQuote = ({ quoteData }: any) => {
  const { title, quote } = quoteData;
  return (
    <div className="prayerquote container">
      <div className="prayerquote-wrapper">
        <span className="prayerquote-symbol">
          <Quotesymbol />
        </span>
        {quote && (
          <div className="prayerquote-quotes">
            <ReactMarkdown>{quote.data.quote}</ReactMarkdown>
          </div>
        )}
        {title && (
          <h4 className="prayerquote-title">
            <ReactMarkdown>{title}</ReactMarkdown>
          </h4>
        )}
      </div>
    </div>
  );
};
export { PrayerQuote };
