import React from 'react';
import ReactMarkdown from 'react-markdown';

export const Caption = ({ summary, title, bgColor, contentAlignment }: any) => {
  return (
    <div className="captionlist">
      <div className={`${bgColor ? 'backgroundclr' : ''}`}>
        <div className="commonlist-section">
          {title && (
            <div className="heading-design">
              <h3 className="section-title">
                <ReactMarkdown>{title}</ReactMarkdown>
              </h3>
            </div>
          )}
          <div className="commonlist-wrapper">
            <div className={`page-content ${contentAlignment}`}>
              <ReactMarkdown>{summary.data.summary}</ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
