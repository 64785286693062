import { FILTER_POST,CLEAR_POSTS, STORE_POST } from 'Redux/actionTypes';

type InitialStateType = {
  posts: [];
  searchResults: [];
};

const InitialState: InitialStateType = {
  posts: [],
  searchResults: [],
};

const FilterReducer: any = (state = InitialState, action: any) => {
  switch (action.type) {
    case STORE_POST:
      return { ...state, posts: action.payload, searchResults: action.payload };

    case FILTER_POST:
      return { ...state, posts: action.payload };

    case CLEAR_POSTS:
      return { ...state, posts: action.payload };
    default:
      return state;
  }
};
export { FilterReducer };
