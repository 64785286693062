/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { Link } from 'gatsby';
import BannerArrow from 'assets/svg/banner-arrow-right.svg';
import { ImagePropTypes } from 'types/ComponentTypes/GatsbyImage';

interface HeroBannerSliderProps {
  heroBanner: {
    title: string;
    tagline: string;
    delay: string;
    id: string;
    ctaLink: String;
    ctaText: String;
    image: ImagePropTypes;
    video: {
      localFile: {
        publicURL: String;
      };
    };
  }[];
}

const HeroBannerSlider: FC<HeroBannerSliderProps> = ({ heroBanner }) => {
  return (
    <div className="banner-slider-container">
      <Swiper
        pagination={{
          clickable: true,
        }}
        slidesPerView={1}
        speed={1200}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 30000,
          disableOnInteraction: false,
        }}
        loop
        className="banner-swiper"
      >
        {heroBanner.map((item: any) => {
          const { image, video, title, tagline, delay, ctaLink, ctaText } =
            item;
          const BannerImage: any =
            image && image.localFile
              ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
              : null;
          return (
            <SwiperSlide key={title} data-swiper-autoplay={delay} >
              <div className="banner-slider">
                {video ? (
                  <div className="banner-slider-Media-wrapper">
                    <video autoPlay muted loop playsInline>
                      <source
                        src={video?.localFile?.publicURL}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                ) : (
                  <div className="banner-slider-Media-wrapper">
                    <GatsbyImage image={BannerImage} alt="" />
                  </div>
                )}
                <div className="banner-slider-inner-container">
                  <div className="banner-slider-inner">
                    {title && <h2 className="banner-slider-title">{title}</h2>}
                    {tagline && (
                      <div className="banner-slider-inner-tagline">
                        {tagline}
                      </div>
                    )}
                    {ctaLink && (
                      <Link
                        className="banner-slider-inner-ctalink"
                        target="_blank"
                        to={ctaLink}
                      >
                        <span>{ctaText}</span>
                        <BannerArrow />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export { HeroBannerSlider };
