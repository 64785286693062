import React, { FC } from 'react';
import NextArrow from 'assets/svg/nextarrow.svg';
import PrevArrow from 'assets/svg/prevarrow.svg';
import { usePrevNextButton } from './usePrevNextButton';

export interface PrevNextButtonPropsType {
  currentPage: number;
  totalCount: number;
  pageSize: number;
  onPageChange: () => void;
}

export const PrevNextButton: FC<PrevNextButtonPropsType> = ({
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
}) => {
  const prevNextButtonRange: any = usePrevNextButton({
    currentPage,
    totalCount,
    pageSize,
  });
  if (
    currentPage === 0 ||
    !prevNextButtonRange ||
    prevNextButtonRange?.length < 2
  ) {
    return null;
  }
  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  const lastPage = prevNextButtonRange[prevNextButtonRange.length - 1];
  return (
    <div className="pagination-section">
      <button
        type="button"
        className="pagination-left-arrow"
        onClick={onPrevious}
        disabled={currentPage === 1}
      >
        <PrevArrow className={currentPage === 1 ? 'disabled' : ''} />
        Prev
      </button>
      <button
        type="button"
        className="pagination-right-arrow"
        onClick={onNext}
        disabled={currentPage === lastPage}
      >
        Next
        <NextArrow className={currentPage === lastPage ? 'disabled' : ''} />
      </button>
    </div>
  );
};
