import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ImagePropTypes } from 'types/ComponentTypes/GatsbyImage';
import { SlideInAnimation } from 'animations';
import { CTALink } from 'components';

interface TextImageProps {
  twoColumnsList: {
    title: string;
    ctaLink?: string;
    ctaText?: string;
    description: {
      data: {
        description: String | any;
      };
    };
    image: ImagePropTypes;
  }[];
}
export const TextImage: FC<TextImageProps> = ({ twoColumnsList }) => {
  return (
    <div className="container">
      <div className="summary">
        {twoColumnsList?.map((item: any) => {
          const { title, description, image, ctaLink, ctaText } = item;
          const listImage: any =
            image && image.localFile
              ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
              : null;

          return (
            <div className="summary-row" key={title}>
              <SlideInAnimation
                className="summary-row-wrapper"
                direction="left"
              >
                <h4 className="summary-row-title">
                  <ReactMarkdown>{title}</ReactMarkdown>
                </h4>
                <div className="summary-row-desc">
                  <ReactMarkdown>
                    {description?.data?.description}
                  </ReactMarkdown>
                </div>
                {ctaText && ctaLink && (
                  <CTALink ctaLink={ctaLink} className="summary-row-cta">
                    {ctaText}
                  </CTALink>
                )}
              </SlideInAnimation>
              <SlideInAnimation className="summary-row-image" direction="right">
                {listImage && (
                  <GatsbyImage
                    image={listImage}
                    alt={item.image.localFile?.alternativeText ?? (title || '')}
                  />
                )}
              </SlideInAnimation>
            </div>
          );
        })}
      </div>
    </div>
  );
};
