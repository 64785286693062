import { useStaticQuery, graphql } from 'gatsby';

const MenuQuery = () => {
  return useStaticQuery(graphql`
    query MenuQuery {
      strapiGlobal {
        massTime {
          DailyMassTimings {
            id
            day
            time
            note
          }
          SundayMassTimings {
            day
            time
          }
          dayTitle
          timeTitle
          note {
            data {
              note
            }
          }
        }
        pagePopup {
          title
          slug
          showOnPageLoad
          description {
            data {
              description
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        header {
          weeklyMassTimeTitle
          weeklyMassTimeSlug
          headerLinks {
            title
            link
          }
        }
        mainNavLinks {
          title
          link
        }
        footer {
          address {
            data {
              address
            }
          }
        }
      }
    }
  `);
};
export { MenuQuery };
