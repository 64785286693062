import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const FullWidthImage = ({ image }: any) => {
  const fullWidthImage: any =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;
  return (
    <div className="fullwidthimage">
      <GatsbyImage image={fullWidthImage} alt="fullwidth" />
    </div>
  );
};
