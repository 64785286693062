/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/void-dom-elements-no-children */
import React from 'react';
import { ErrorMessage } from '@hookform/error-message';

const ParishChecklist = ({ section, formregister, formerrors }: any) => {
  return (
    <div className="Parish-checklist-container">
      <div className="Parish-checklist-container-list">
        {section.formTitle.map((item: any) => {
          const { title } = item;
          return (
            <div key={title} className="Parish-checklist-container-list-item">
              <label
                htmlFor={title}
                className="Parish-checklist-container-list-item-label"
              >
                <input
                  {...formregister('parishMinistry', {
                    validate: value => value.length >= 1,
                  })}
                  type="checkbox"
                  value={title}
                  id={title}
                />

                <span>{title}</span>
              </label>
            </div>
          );
        })}
        <div className="error-message">
          <ErrorMessage
            errors={formerrors}
            name="parishMinistry"
            render={() => <p>Please fill out this field.</p>}
          />
        </div>
      </div>
    </div>
  );
};

export { ParishChecklist };
