import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Header, Footer } from 'components';
import 'sass/style.scss';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import { FooterQuery } from 'queries/footer';
import Youtube from 'assets/svg/youtube-video.svg';
import PDF from 'assets/svg/pdf.svg';

export interface LayoutProps {
  children: JSX.IntrinsicElements['div'];
}

const Layout: FC<LayoutProps> = ({ children }: any) => {
  const datas = FooterQuery();
  const footer = datas?.strapiGlobal?.footer;
  const socialMediaLinks = datas?.strapiGlobal?.socialMediaLinks;
  const subscribe = datas?.strapiGlobal?.subscribe;
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <>
      <Youtube style={{ display: 'none' }} />
      <PDF style={{ display: 'none' }} />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
        <Footer
          footer={footer}
          socialMediaLinks={socialMediaLinks}
          subscribe={subscribe}
        />
      </div>
    </>
  );
};

export default Layout;
