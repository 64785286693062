import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CTALink } from 'components';
import { BulletinQuery } from 'queries/bulletin';
import Pdf from 'assets/svg/pdf.svg';
import { ImagePropTypes } from 'types/ComponentTypes/GatsbyImage';
import moment from 'moment';

interface BlockCardProps {
  data: {
    ctaLink: string;
    ctaText: string;
    summary?: string;
    description?: {
      data: {
        description: string;
      };
    };
    showNewsletters?: number;
    title: string;
    image: ImagePropTypes;
  };
}
interface BulletinArray {
  node: {
    date: string;
    file: {
      localFile: {
        publicURL: string;
      };
      thumbnail: ImagePropTypes;
    };
  };
}

interface BulletinListProps {
  bulletinData: Array<BulletinArray>;
  showNewsletters: number;
}

const BulletinList: FC<BulletinListProps> = ({
  bulletinData,
  showNewsletters,
}) => {
  return (
    <ul className="homeblock-list">
      {bulletinData.slice(0, showNewsletters).map(item => {
        const bulletinDate = moment(item.node.date).format('MMM DD, YYYY');
        return (
          <li className="homeblock-list-item" key={bulletinDate}>
            <Pdf />
            <a
              href={item.node.file?.localFile?.publicURL}
              target="_blank"
              rel="noreferrer"
            >
              Newsletter - {bulletinDate}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export const BlockCard: FC<BlockCardProps> = ({ data }) => {
  const { title, summary, ctaLink, ctaText, image, showNewsletters } = data;
  const bulletinData = BulletinQuery();
  const imageItem: any =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;
  return (
    <div className="homeblock-card" key={title}>
      <div className="homeblock-content">
        <h3>{title}</h3>
        <p>{summary}</p>
        {showNewsletters && (
          <BulletinList
            bulletinData={bulletinData.allStrapiBulletin.edges}
            showNewsletters={showNewsletters}
          />
        )}
        <CTALink className="homeblock-cta" ctaLink={ctaLink}>
          {ctaText}
        </CTALink>
      </div>
      <div className="card-overlay" />
      <div className="homeblock-image">
        <GatsbyImage
          image={imageItem}
          alt={image?.alternativeText ?? (title || '')}
        />
      </div>
    </div>
  );
};
