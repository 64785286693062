import React, { FC } from 'react';
import { BlockCard } from 'components';
import { ImagePropTypes } from 'types/ComponentTypes/GatsbyImage';

interface BlockSectionArray {
  ctaLink: string;
  ctaText: string;
  description?: string;
  showNewsletters?: number;
  title: string;
  image: ImagePropTypes;
}

interface BlockSectionProps {
  blockContent: Array<BlockSectionArray>;
}

export const BlockSection: FC<BlockSectionProps> = ({ blockContent }) => {
  return (
    <div className="homeblock">
      {blockContent.map((section: any) => {
        return (
          <React.Fragment key={section.title}>
            {section &&
              section?.active &&
              section.internal &&
              section.internal.type ===
                'STRAPI__COMPONENT_DYNAMIC_HOME_BLOCK_CARD' && (
                <BlockCard data={section} key={section.id} />
              )}
            {section &&
              section?.active &&
              section.internal &&
              section.internal.type ===
                'STRAPI__COMPONENT_DYNAMIC_BULLETIN_CARD' && (
                <BlockCard data={section} key={section.id} />
              )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
