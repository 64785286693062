import { useStaticQuery, graphql } from 'gatsby';

export const homeReadingsCalendarQuery = () => {
  return useStaticQuery(graphql`
    query HomeReadings {
      strapiGlobal {
        massTime {
          DailyMassTimings {
            id
            day
            time
            note
          }
          SundayMassTimings {
            day
            time
          }
          dayTitle
          timeTitle
          note {
            data {
              note
            }
          }
        }
      }
      allStrapiEvent {
        nodes {
          id
          date
          events {
            id
            title
            time
          }
        }
      }
      allStrapiDailyMassReading {
        edges {
          node {
            readingLink
            readingTitle
            date
            content {
              data {
                content
              }
            }
            id
          }
        }
      }
    }
  `);
};
