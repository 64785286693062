/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC } from 'react';
import ReactModal from 'react-modal';

interface VideoModalProp {
  isModalOpen: any;
  setisModalOpen: any;
  modalPosterImage: any;
  modalVideo: any;
  modalYoutubeID: any;
}
const VideoModal: FC<VideoModalProp> = ({
  isModalOpen,
  setisModalOpen,
  modalPosterImage,
  modalVideo,
  modalYoutubeID,
}) => {
  const handleModalClose = () => {
    setisModalOpen(false);
  };
  return (
    <div>
      <ReactModal
        closeTimeoutMS={1000}
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        ariaHideApp={false}
        portalClassName="ReactModalPortal videoplayer-modal"
      >
        <button
          type="button"
          onClick={() => handleModalClose()}
          className="modal-close"
        >
          <span className="close">&#x2715;</span>
        </button>
        <div className="modal-img-container">
          {modalYoutubeID ? (
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${modalYoutubeID}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          ) : (
            <video
              preload="auto"
              autoPlay
              loop
              playsInline
              poster={modalPosterImage?.localFile.publicURL}
              muted
              controls
              className="video"
            >
              <source src={modalVideo?.localFile?.publicURL} type="video/mp4" />
            </video>
          )}
        </div>
      </ReactModal>
    </div>
  );
};
export { VideoModal };
