import { useStaticQuery, graphql } from 'gatsby';

const RecordedmassQuery = () => {
  return useStaticQuery(graphql`
    query RecordedmassQuery {
      allStrapiMassRecording(sort: { createdAt: DESC }) {
        edges {
          node {
            id
            youtubeUrl
            title
            thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `);
};
export { RecordedmassQuery };
