import React, { useEffect, useState } from 'react';
import ScrollArrow from 'assets/svg/scroll-arrow.svg';

export const ScrollTop = () => {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 800) {
      setVisible(true);
    } else if (scrolled <= 800) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);
  return (
    <button
      type="button"
      className="scroll_icon"
      onClick={scrollToTop}
      style={{ display: visible ? 'inline' : 'none' }}
    >
      <ScrollArrow />
    </button>
  );
};
