/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react';
import { FormsQuery } from 'queries/formsQuery';
import { Form } from 'components';
import { useLocation } from '@reach/router';

const FormButton = ({ section }: any) => {
  const [isModal, setisModal] = useState(false);
  const [filteredFormData, setFilteredFormData] = useState([]) as any;
  const formsQuery = FormsQuery();
  const formData = formsQuery.allStrapiForm.edges;
  const location = useLocation();

  if (!section?.form?.title) {
    return null;
  }

  const handler = () => {
    setisModal(true);
    if (
      location.pathname.split('/').filter(Boolean).pop() !==
      filteredFormData[0].node.slug
    ) {
      window.history.pushState(
        '',
        '',
        `${location.pathname}/${filteredFormData[0].node.slug}`
      );
    }
  };
  useEffect(() => {
    const filteredForm = formData.filter(item => {
      return item.node.title === section.form.title;
    });
    if (filteredForm.length > 0) {
      setFilteredFormData(filteredForm);
    }
    if (
      location.pathname.split('/').filter(Boolean).pop() ===
      filteredForm[0].node.slug
    ) {
      setisModal(true);
    }
  }, []);

  return (
    <div>
      <div className="modal-button-container">
        <button
          type="button"
          className="modal-button"
          onClick={() => handler()}
        >
          {section.form.title}
        </button>
      </div>
      <Form
        location={location}
        isModal={isModal}
        setisModal={setisModal}
        modelContent={filteredFormData}
        formTypes={section.form.title}
      />
    </div>
  );
};

export { FormButton };
