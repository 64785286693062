/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterPosts, storePost } from 'Redux/actions';
import Select from 'react-select';
import FilterIcon from 'assets/svg/filter-icon.svg';
import { BulletinQuery } from 'queries/bulletin';
import { Bulletin } from 'components';

function getList(start: any, end: any) {
  const options = [];
  for (let i = start; i <= end; i += 1) {
    options.push({ value: i, label: i });
  }
  options.push({ value: 'All', label: 'All' });
  return options;
}

export const BulletinFilter = () => {
  const dispatch = useDispatch();
  const bulletinData = BulletinQuery();
  const { posts } = useSelector((state: any) => state.FilterReducer);
  const yearList = getList(2015, new Date().getFullYear()).reverse();
  const [filterYear, setFilterYear] = useState(yearList[1].value);
  const currentYear = new Date().getFullYear();
  const latestYearData = yearList.filter(
    element => element.value === 'All' || element.value === currentYear
  );
  const latestYear = latestYearData
    .filter(element => element.value !== 'All')
    .sort((a, b) => b.value - a.value)[0];

  const selectStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: 'none',
      outline: 0,
      border: 0,
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
      color: '#fff',
      cursor: 'pointer',
    }),
    option: (styles: any, { isDisabled, isSelected }: any) => {
      return {
        ...styles,
        textAlign: 'center',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        backgroundColor: isSelected ? '#d52430' : 'white',
        color: isSelected ? '#fff' : '#373b4d',
      };
    },
  };

  useEffect(() => {
    dispatch(storePost(bulletinData.allStrapiBulletin.edges));
  }, []);

  useEffect(() => {
    if (filterYear !== '') dispatch(filterPosts(filterYear) as any);
  }, [filterYear]);

  return (
    <>
      <div className="container bulletin-filter">
        <div className="bulletin-filter-wrapper">
          <div className="bulletin-filter-date-wrapper">
            <div className="bulletin-filter-icon">
              <FilterIcon />
            </div>
            <div className="bulletin-filter-item">
              <label htmlFor="" className="bulletin-filter-label">
                Year
              </label>
              <Select
                className="bulletin-filter-select"
                defaultValue={{
                  value: latestYear.value,
                  label: latestYear.label,
                }}
                value={filterYear.value}
                onChange={e => setFilterYear(e.value)}
                options={yearList}
                styles={selectStyles}
                classNamePrefix="select"
              />
            </div>
          </div>
        </div>
      </div>
      {posts && posts.length > 0 ? (
        <Bulletin bulletins={posts} title="" />
      ) : (
        <div className="sub-heading">No records found!</div>
      )}
    </>
  );
};
