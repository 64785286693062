import { useStaticQuery, graphql } from 'gatsby';

const BulletinQuery = () => {
  return useStaticQuery(graphql`
    query BulletinQuery {
      allStrapiBulletin(sort: { date: DESC }) {
        edges {
          node {
            thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 300, height: 400)
                }
              }
            }
            file {
              localFile {
                publicURL
              }
            }
            date
          }
        }
      }
    }
  `);
};
export { BulletinQuery };
