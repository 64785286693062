import React from 'react';
import { PageHeading } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

export const OurStaff = ({
  name,
  Heading,
  subHeading,
  description,
  image,
  active,
}: any) => {
  const StaffImage: any =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;
  return (
    <div className="ourstaff">
      <PageHeading
        Heading={Heading}
        subHeading={subHeading}
        content=""
        active={active || typeof active === 'undefined'}
      />
      <div className={`ourstaff-img ${Heading}`}>
        <GatsbyImage
          image={StaffImage}
          alt={image?.alternativeText ?? (image || '')}
        />
        <span className="ourstaff-detail-name">{name}</span>
      </div>

      <div className="ourstaff-summary">
        <ReactMarkdown>{description}</ReactMarkdown>
      </div>
    </div>
  );
};
