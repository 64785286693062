import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const MarriageSection = ({
  weddingPhotographesTitle,
  weddingPhotographesLink,
  weddingPhotos,
}: any) => {
  return (
    <div className="marriage-section">
      <div className="marriage-section-wrapper">
        {weddingPhotos.map((item: any) => {
          const imageItem: any =
            item?.image && item?.image?.localFile
              ? getImage(
                item?.image?.localFile.childImageSharp?.gatsbyImageData
              )
              : null;
          return (
            <div className="marriage-section-image" key={item.id}>
              <GatsbyImage
                image={imageItem}
                alt={
                  item.image?.localFile?.publicURL?.alternativeText ??
                  (item.image?.localFile?.publicURL || '')
                }
              />
            </div>
          );
        })}
        <div className="marriage-section-photographer">
          <h3 className="photographer-heading">{weddingPhotographesTitle} </h3>
          <Link to={`${weddingPhotographesLink}`} className="photographer-link">
            <span>{weddingPhotographesLink}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
