import React, { useRef, useEffect, useState } from 'react';
import { truncate } from 'utils';
import { CTALink } from 'components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

export const BannerImageList = ({
  title,
  description,
  bannerListItem,
  image,
  hasBgImg = true,
}: any) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };
  const characterLimit = 350;
  const imageItem: any =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;

  const linkRefs = useRef([]);
  const sliderRef = useRef(null);

  const addLinkRefs = el => {
    if (el && !linkRefs.current.includes(el)) {
      linkRefs.current.push(el);
    }
  };
  useEffect(() => {
    if (hasBgImg) {
      linkRefs.current.forEach((el: any, index: number) => {
        el.addEventListener('click', () => {
          sliderRef.current.swiper.slideTo(index);
        });
      });
    }
    return () => { };
  }, []);
  return (
    <div
      className="bannerlist"
      style={{
        backgroundImage: `url(${image.localFile.childImageSharp?.gatsbyImageData.images.fallback.src})`,
      }}
    >
      {hasBgImg && (
        <div className="bannerlist-slider-img">
          <Swiper
            slidesPerView={1}
            speed={1000}
            effect="fade"
            modules={[EffectFade]}
            ref={sliderRef}
            allowTouchMove={false}
            shortSwipes={false}
            longSwipes={false}
          >
            {bannerListItem.map((item: any) => {
              return (
                <SwiperSlide key={item.title}>
                  {item.image ? (
                    <>
                      <GatsbyImage
                        image={
                          item.image?.localFile?.childImageSharp
                            ?.gatsbyImageData
                        }
                        alt={item.title}
                      />
                      <div className="bannerlist-slider-overlay showOverlay" />
                    </>
                  ) : (
                    ''
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
      <div className="bannerlist-content">
        <h2 className="bannerlist-title">{title}</h2>
        <div className="bannerlist-slider-container">
          {!bannerListItem.length && description && (
            <ReactMarkdown className="bannerlist-slider">
              {description.data.description}
            </ReactMarkdown>
          )}
          {bannerListItem.map((tab: any, index: number) => (
            <div
              className="bannerlist-slider"
              key={tab.title}
              style={{ display: activeTab === index ? 'flex' : 'none' }}
            >
              <div className="bannerlist-slider-wrapper">
                <h2 className="bannerlist-slider-title">{tab.title}</h2>
                <p className="note-section-slider-description">
                  {truncate(tab.description.data.description, characterLimit)}
                </p>
                <CTALink
                  ctaLink={tab.ctaLink}
                  className="bannerlist-slider-cta"
                >
                  {tab.ctaText}
                </CTALink>
              </div>
            </div>
          ))}
          <ul className="bannerlist-menu">
            {bannerListItem.map((tab: any, index: number) => (
              <li key={tab.title}>
                <button
                  type="button"
                  className={activeTab === index ? 'active' : ''}
                  onClick={() => handleTabClick(index)}
                  ref={addLinkRefs}
                >
                  {tab.title}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
