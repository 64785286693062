/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState, useEffect } from 'react';
import Calender from 'assets/svg/calendar-icon.svg';
// import Facebook from 'assets/svg/facebook-icon.svg';
// import Instagram from 'assets/svg/instagram-icon.svg';
// import Twitter from 'assets/svg/twitter-icon.svg';
// import Youtube from 'assets/svg/youtube-icon.svg';
import { Link } from 'gatsby';
import { MenuQuery } from 'queries/menu';
import { useLocation } from '@reach/router';
import { ModalComponent, ContactMap, MassTimesCard } from 'components';
import ReactMarkdown from 'react-markdown';

const Menu: FC = ({ toggleMenu, setIsMenuOpen }: any) => {
  const [active, setActive] = useState();
  const location = useLocation();
  const menuItems = MenuQuery();
  const massTimeData = menuItems.strapiGlobal.massTime;
  const [activeClass, setActiveClass] = useState(0);
  const handleItemClick = i => {
    setActiveClass(i);
    toggleMenu();
  };
  const massTimesHeadingData = {
    heading: '**Mass** Times',
  };
  const [activeNavClass, setActiveNavClass] = useState(0);
  const handleNavClick = i => {
    setActiveNavClass(i);
    setIsMenuOpen(false);
  };
  useEffect(() => {
    menuItems.strapiGlobal.mainNavLinks.map((menu: any) => {
      if (location.pathname === `/${menu.link}/`) {
        setActiveClass(menu);
        setActiveNavClass(0);
      }
    });
    menuItems.strapiGlobal.header.headerLinks.map((nav: any) => {
      if (location.pathname === `/${nav.link}/`) {
        setActiveNavClass(nav);
        setActiveClass(0);
      }
    });
    if (location.pathname === '/') {
      setActiveClass(0);
      setActiveNavClass(0);
    }
  }, [location]);
  const [isModal, setisModal] = useState(false);
  const handleModalOpen = () => {
    setIsMenuOpen(false);
    setisModal(true);
  };
  const closeModal = () => {
    setisModal(false);
  };
  return (
    <nav className="menu-wrapper">
      <div className="menu-details">
        <div className="menu-details-address">
          {menuItems.strapiGlobal.footer.address.data.address}
        </div>
        {/* <div className="social-icons">
          <Facebook />
          <Instagram />
          <Twitter />
          <Youtube />
        </div> */}
      </div>
      <ul className="menu-list">
        {menuItems.strapiGlobal.mainNavLinks.map((menu: any, index: any) => (
          <li
            onClick={() => {
              if (active === index) {
                setActive();
              } else {
                setActive(index);
              }
            }}
            className="menu-list-item"
            key={menu.link}
          >
            <Link
              to={`/${menu.link}`}
              className={
                activeClass === menu
                  ? 'menu-list-item-link active'
                  : 'menu-list-item-link'
              }
              onClick={() => handleItemClick(menu)}
            >
              {menu.title}
            </Link>
          </li>
        ))}
      </ul>
      <ul className="header-nav">
        {menuItems.strapiGlobal.header.headerLinks.map(
          (nav: any, index: any) => (
            <li
              onClick={() => {
                if (active === index) {
                  setActiveNavClass();
                } else {
                  setActiveNavClass(index);
                }
              }}
              className="header-nav-item"
              key={nav.title}
            >
              <Link
                to={`/${nav.link}`}
                className={
                  activeNavClass === nav
                    ? 'header-nav-item-link active'
                    : 'header-nav-item-link'
                }
                onClick={() => handleNavClick(nav)}
              >
                {nav.title}
              </Link>
            </li>
          )
        )}
      </ul>
      <button
        type="button"
        className="weekly-timings"
        onClick={() => {
          handleModalOpen();
        }}
      >
        <Calender />
        <span>{menuItems.strapiGlobal.header.weeklyMassTimeTitle}</span>
      </button>
      <ModalComponent
        isModal={isModal}
        closesModal={closeModal}
        className="home-modal"
      >
        <div className="mass-timings">
          <h3 className="mass-timings-title">
            <ReactMarkdown>{massTimesHeadingData.heading}</ReactMarkdown>
          </h3>

          <MassTimesCard massTimeData={massTimeData} />
          {massTimeData?.note?.data?.note && (
            <ReactMarkdown>{massTimeData.note.data.note}</ReactMarkdown>
          )}
        </div>
        <ContactMap />
      </ModalComponent>
    </nav>
  );
};
export { Menu };
