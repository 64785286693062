import React from 'react';
import { homeReadingsCalendarQuery } from 'queries/homeReadingsCalendar';
import { MassTimesCard, TodaysReading, Calender } from 'components';
import ReactMarkdown from 'react-markdown';

export const HomeReadingsCalendar = () => {
  const massTimesHeadingData = {
    heading: 'Mass Times',
  };
  const {
    allStrapiDailyMassReading,
    allStrapiEvent,
    strapiGlobal: { massTime },
  } = homeReadingsCalendarQuery();
  const dailyMassReading = allStrapiDailyMassReading?.edges;
  return (
    <div className="card-container">
      <div className="card-container-wrapper">
        <div className="card-section">
          <div className="card-heading-wrapper">
            <h2 className="card-heading-date">
              <span className="card-heading">
                {massTimesHeadingData.heading}
              </span>
            </h2>
          </div>
          <MassTimesCard massTimeData={massTime} />
          {massTime?.note?.data?.note && (
            <ReactMarkdown>{massTime.note.data.note}</ReactMarkdown>
          )}
        </div>
      </div>
      <div className="card-container-wrapper">
        <TodaysReading dailyMassReading={dailyMassReading} />
      </div>
      <div className="card-container-wrapper">
        <Calender calenderData={allStrapiEvent.nodes} />
      </div>
    </div>
  );
};
