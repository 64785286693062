/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from 'react';
import { Link } from 'gatsby';

interface CTALinkProps {
  ctaLink: string;
  children: ReactNode;
  className?: string;
}

export const CTALink = (props: CTALinkProps) => {
  const { ctaLink, children, ...otherProps } = props;
  if (!ctaLink) {
    return null;
  }
  if (ctaLink.includes('://')) {
    return (
      <a href={ctaLink} target="_blank" {...otherProps} rel="noreferrer">
        {children}
      </a>
    );
  }
  const localLink =
    (ctaLink.startsWith('/') ? `${ctaLink}` : `/${ctaLink}`) || '/';
  return (
    <Link to={localLink} {...otherProps}>
      {children}
    </Link>
  );
};
