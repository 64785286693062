import React from 'react';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';

const MeetingSchedule = ({
  Heading,
  dateTitle,
  timeTitle,
  dateWithTime,
  bgColor,
  content,
  summary,
}: any) => {
  return (
    <div
      className={`container meeting-schedule ${bgColor ? 'backgroundclr' : ''}`}
    >
      <div className="meeting-schedule-wrapper">
        <h3 className="meeting-schedule-title">
          <ReactMarkdown>{Heading}</ReactMarkdown>
        </h3>
        <div className="meeting-schedule-description">
          {content && <ReactMarkdown>{content}</ReactMarkdown>}
        </div>
        <div className="meeting-schedule-detail">
          <div className="meeting-schedule-subheading">
            {dateTitle && (
              <h5 className="meeting-schedule-subheading-date">{dateTitle}</h5>
            )}
            {timeTitle && (
              <h5 className="meeting-schedule-subheading-time">{timeTitle}</h5>
            )}
          </div>
          {dateWithTime.map((item: any) => {
            const { date, time } = item;
            const day = moment(date).format('dddd D MMMM YYYY');
            return (
              <div className="meeting-schedule-content" key={date}>
                <div className="meeting-schedule-content-date">{day}</div>
                <div className="meeting-schedule-content-time">{time}</div>
              </div>
            );
          })}
        </div>
        <div className="meeting-schedule-note">
          {summary && <ReactMarkdown>{summary}</ReactMarkdown>}
        </div>
      </div>
    </div>
  );
};
export { MeetingSchedule };
