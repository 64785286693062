import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

const FeaturedContent = ({ featuredContentData }: any) => {
  const { title, tagline, summary, image, showCurveImage } =
    featuredContentData;
  const imageItem: any =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;
  return (
    <div className="message-holiness">
      <div className="container">
        <div className="message-holiness-wrapper">
          <div className="message-holiness-content">
            {title && (
              <h4 className="message-holiness-content-title">
                <ReactMarkdown>{title}</ReactMarkdown>
              </h4>
            )}
            {tagline && (
              <div className="message-holiness-content-tagline">
                <ReactMarkdown>{tagline}</ReactMarkdown>
              </div>
            )}
            {summary && (
              <div className="message-holiness-content-summary">
                <ReactMarkdown>{summary.data.summary}</ReactMarkdown>
              </div>
            )}
          </div>

          <div
            className={`message-holiness-img ${
              showCurveImage && 'curvedimage'
            }`}
          >
            {imageItem && (
              <GatsbyImage
                image={imageItem}
                alt={image?.alternativeText ?? (title || '')}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export { FeaturedContent };
