import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';

interface TitleAndSummaryProps {
  title: String | any;
  summary: {
    data: {
      summary: String | any;
    };
  };
}
export const TitleAndSummary: FC<TitleAndSummaryProps> = ({
  title,
  summary,
}) => {
  return (
    <div className="title-summary page-heading">
      <div className="heading-design">
        <h3 className="section-title">
          <ReactMarkdown>{title}</ReactMarkdown>
        </h3>
      </div>
      {summary && (
        <div className="page-content">
          <ReactMarkdown>{summary.data.summary}</ReactMarkdown>
        </div>
      )}
    </div>
  );
};
