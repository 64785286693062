import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import moment from 'moment';
import { ImagePropTypes } from 'types/ComponentTypes/GatsbyImage';
import Placeholder from 'assets/images/newsletter-placeholder.jpg';

interface BulletinsProps {
  bulletins: {
    id: String;
    date: String;
    file: {
      localFile: {
        publicURL: String | any;
      };
    };
    thumbnail: ImagePropTypes;
  }[];
}

export const Bulletin: FC<BulletinsProps> = ({ bulletins }) => {
  return (
    <div className="container">
      <div className="thumbnail-wrapper">
        {bulletins.map((item: any) => {
          const { id, date, thumbnail, file } = item.node;
          const bulletindate = moment(date).format('MMM DD, YYYY');

          const imageItem: any =
            thumbnail && thumbnail?.localFile
              ? getImage(thumbnail?.localFile.childImageSharp?.gatsbyImageData)
              : null;
          return (
            <div className="thumbnail-card" key={id}>
              <a
                href={file?.localFile?.publicURL}
                target="_blank"
                rel="noreferrer"
                className="thumbnail-card-cta"
              >
                <div className="thumbnail-card-image pdf">
                  {imageItem && <GatsbyImage image={imageItem} alt={date} />}
                  {!imageItem && <img src={Placeholder} alt="placeholder" />}
                  <svg
                    className="thumbnail-card-icon"
                    viewBox="0 0 55 70"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <use href="#pdf" fill="red" />
                  </svg>
                </div>

                <span className="thumbnail-card-title">{bulletindate}</span>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};
