import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CTALink, PageHeading } from 'components';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';

const ArticlesList = ({ blogSlider, heading }: any) => {
  return (
    <div className="container">
      <div className="blogs-wrapper blogs-list">
        <div className="blogs-wrapper-heading blogs-heading">
          <PageHeading Heading={heading} />
        </div>

        <div className="blogs-wrapper-content">
          {blogSlider &&
            blogSlider.length > 0 &&
            blogSlider.map((item: any) => {
              if (item.articles && item.articles.length > 0) {
                const { title, date, description, image, slug } =
                  item.articles[0];

                const Image: any =
                  image && image && image.localFile
                    ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
                    : null;
                return (
                  <div className="blogs-wrapper-content-wrapper" key={title}>
                    <CTALink ctaLink={`/blogs/${slug}`}>
                      {Image && (
                        <GatsbyImage image={Image} alt={image[0]?.name} />
                      )}
                      {title && (
                        <h2 className="blogs-wrapper-content-title">{title}</h2>
                      )}
                    </CTALink>

                    {description && (
                      <div className="blogs-wrapper-content-description">
                        <ReactMarkdown>
                          {description.data.description}
                        </ReactMarkdown>
                      </div>
                    )}
                    {date && (
                      <div className="blogs-wrapper-content-date">
                        {moment(date).format('MMM D, YYYY')}
                      </div>
                    )}
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    </div>
  );
};

export { ArticlesList };
