/* eslint-disable jsx-a11y/media-has-caption */
import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ImagePropTypes } from 'types/ComponentTypes/GatsbyImage';

interface BannerVideoProps {
  image: ImagePropTypes;
  video: {
    localFile: {
      publicURL: String | any;
    };
  };
}

export const BannerVideo: FC<BannerVideoProps> = ({ image, video }) => {
  const bannerImageItem: any =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;

  return (
    <div className="banner-video">
      <div className="banner-video-container">
        <div className="banner-video-wrapper">
          {video?.localFile?.publicURL ? (
            <video
              poster={image?.localFile?.publicURL}
              loop
              autoPlay
              muted
              playsInline
            >
              <source src={video?.localFile?.publicURL} type="video/mp4" />
            </video>
          ) : (
            <GatsbyImage image={bannerImageItem} alt={image?.name} />
          )}
        </div>
      </div>
    </div>
  );
};
