import { useStaticQuery, graphql } from 'gatsby';

const FormsQuery = () => {
  return useStaticQuery(graphql`
    query FormsQuery {
      allStrapiForm {
        edges {
          node {
            title
            slug
            formTitle {
              data {
                formTitle
              }
            }
            formSummary {
              data {
                formSummary
              }
            }
            formContent {
              data {
                formContent
              }
            }
            captchaInfo {
              data {
                captchaInfo
              }
            }
            dynamicComponents {
              ... on STRAPI__COMPONENT_DYNAMIC_CAPTION {
                id
                contentAlignment
                title
                summary {
                  data {
                    summary
                  }
                }
                backgroundColor
                internal {
                  type
                }
                active
              }
              ... on STRAPI__COMPONENT_DYNAMIC_BANNER {
                id
                image {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                video {
                  localFile {
                    publicURL
                  }
                }
                internal {
                  type
                }
                active
              }
              ... on STRAPI__COMPONENT_DYNAMIC_CONTENT_LIST {
                id
                contentAlignment
                internal {
                  type
                }
                listSummary {
                  summary {
                    data {
                      summary
                    }
                  }
                  title
                }
                title
                backgroundColor
                active
              }
              ... on STRAPI__COMPONENT_DYNAMIC_CONTENT_WITH_IMAGE {
                id
                name
                active
                internal {
                  type
                }
                image {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(width: 300, height: 300)
                    }
                  }
                }
                pageHeading {
                  description {
                    data {
                      description
                    }
                  }
                  tagline
                  title
                }
              }
              ... on STRAPI__COMPONENT_DYNAMIC_FORM {
                id
                active
                form {
                  title
                }
                internal {
                  type
                }
              }
              ... on STRAPI__COMPONENT_DYNAMIC_FULL_WIDTH_IMAGE {
                id
                active
                internal {
                  type
                }
                image {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              ... on STRAPI__COMPONENT_DYNAMIC_IMAGE_VIDEO_COMPONENT {
                id
                type
                active
                internal {
                  type
                }
                title
                videoImage {
                  videoDuration
                  title
                  date
                  youtubeUrl
                  active
                  image {
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData(width: 500)
                      }
                    }
                  }
                  video {
                    localFile {
                      publicURL
                    }
                  }
                }
              }
              ... on STRAPI__COMPONENT_DYNAMIC_MARRIGE {
                id
                internal {
                  type
                }
                active
                weddingPhotographesLink
                weddingPhotographesTitle
                weddingPhotos {
                  image {
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
              ... on STRAPI__COMPONENT_DYNAMIC_MEETING_SCHEDULE {
                id
                active
                internal {
                  type
                }
                description {
                  data {
                    description
                  }
                }
                dateWithTime {
                  time
                  date
                }
                dateTitle
                title
                timeTitle
                summary {
                  data {
                    summary
                  }
                }
                backgroundColor
              }
              ... on STRAPI__COMPONENT_DYNAMIC_STAFF_MEMBER_AND_PARISH_DETAIL {
                id
                active
                internal {
                  type
                }
                name
                title
                description {
                  data {
                    description
                  }
                }
                image {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(width: 300, height: 300)
                    }
                  }
                }
                role
                phoneNo
                faxNo
                mailId
                parishDetails {
                  title
                  summary {
                    data {
                      summary
                    }
                  }
                }
              }
              ... on STRAPI__COMPONENT_DYNAMIC_TITLE_AND_DESCRIPTION {
                id
                active
                internal {
                  type
                }
                tagline
                description {
                  data {
                    description
                  }
                }
                title
              }
              ... on STRAPI__COMPONENT_DYNAMIC_TITLE_AND_SUMMARY {
                id
                active
                title
                summary {
                  data {
                    summary
                  }
                }
                internal {
                  type
                }
              }
              ... on STRAPI__COMPONENT_DYNAMIC_PARISH_FORM_CHECK_BOX {
                id
                internal {
                  type
                }
                formTitle {
                  title
                }
              }
              ... on STRAPI__COMPONENT_DYNAMIC_TWO_COLUMN {
                id
                active
                internal {
                  type
                }
                title
                twoColumnsList {
                  description {
                    data {
                      description
                    }
                  }
                  title
                  image {
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  ctaText
                  ctaLink
                }
              }
            }
          }
        }
      }
    }
  `);
};
export { FormsQuery };
