/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';
// @ts-ignore
import app from 'gatsby-plugin-firebase-v9.0';
import Loader from 'assets/svg/loader.svg';

type Inputs = {
  name: string;
  email: string;
  subject: string;
  message: string;
  signup: string;
};

interface selectOption {
  title: string;
  value: string;
}

interface ContactFormProps {
  signUp?: [selectOption];
}

const ContactForm = ({ signUp }: ContactFormProps) => {
  const [loading, isloading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isValid },
  } = useForm<Inputs>();

  const onSubmit = (data: any) => {
    const functions = getFunctions(app, 'australia-southeast1');

    if (process.env.NODE_ENV === 'development')
      connectFunctionsEmulator(functions, 'localhost', 5001);

    const handleContactForm = httpsCallable(functions, 'handleContactForm');

    data.formType = 'Contact-form';

    if (isValid) {
      isloading(true);

      handleContactForm(data)
        .then(() => {
          setError('submit', {
            type: 'server',
            message: 'Form submitted succesfully!',
          });
          isloading(false);
          setTimeout(() => {
            reset();
          }, 3000);
        })
        .catch(() => {
          setError('submit', {
            type: 'server',
            message: 'Something went wrong with submission! Try again',
          });
          isloading(false);
          setTimeout(() => {
            reset({ submit: '' });
          }, 5000);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="contact-form-wrapper">
        {errors.submit && (
          <div className="form-error-message">{errors.submit.message}</div>
        )}
        <div className="form-field">
          <input
            type="text"
            className="text-field"
            placeholder="Name"
            {...register('name', {
              required: {
                value: true,
                message: 'Name is required',
              },
              pattern: {
                value: /^[a-zA-Z ]+$/g,
                message: 'Invalid Name. Avoid Special characters',
              },
            })}
          />
        </div>
        {errors.name && (
          <div className="error-message">{errors.name.message}</div>
        )}

        <div className="form-field">
          <input
            type="text"
            className="text-field"
            placeholder="Email"
            {...register('email', {
              required: {
                value: true,
                message: 'Email is required',
              },
              pattern: {
                value:
                  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/g,
                message: 'Invalid Email',
              },
            })}
          />
        </div>

        {errors.email && (
          <div className="error-message">{errors.email.message}</div>
        )}

        {!!signUp?.length && (
          <div className="form-field select">
            <label htmlFor="signup">I can help with</label>
            <select
              id="signup"
              {...register('subject', {
                required: {
                  value: true,
                  message: 'signup is required',
                },
              })}
            >
              {signUp?.map((option: selectOption) => {
                return <option value={option.value}>{option.title}</option>;
              })}
            </select>
          </div>
        )}

        {!signUp?.length && (
          <div className="form-field">
            <input
              type="text"
              className="text-field"
              placeholder="Subject"
              {...register('subject', {
                required: {
                  value: true,
                  message: 'Subject is required',
                },
              })}
            />
          </div>
        )}
        {!signUp?.length && errors.subject && (
          <div className="error-message">{errors.subject.message}</div>
        )}
        <div className="form-field">
          <textarea
            name=""
            placeholder="Message"
            className="text-field"
            id=""
            cols={30}
            rows={20}
            {...register('message', {
              required: {
                value: true,
                message: ' This field is required',
              },
            })}
          />
        </div>
        {errors.message && (
          <div className="error-message">{errors.message.message}</div>
        )}
        <div className="form-cta">
          <button
            type="submit"
            className="form-cta-button"
            {...register('submit')}
          >
            {loading && (
              <span className="form-action-loader">
                <Loader />
              </span>
            )}
            {!loading && <span> Submit Message </span>}
          </button>
        </div>
      </div>
    </form>
  );
};

ContactForm.defaultProps = {
  signUp: null,
};

export { ContactForm };
