/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import ReactMarkdown from 'react-markdown';

const ReconciliationChildForm = ({
  register,
  errors,
  fields,
  formContent,
}: any) => {
  return (
    <div className="form-accordion-item">
      <div className="form-field">
        <label htmlFor="name">
          First Name{' '}
          {fields.required === true ? <span className="required">*</span> : ''}
          {fields.required === true ? (
            <>
              <input
                type="text"
                className="text-field"
                {...register(`${fields.childfirstname}`, {
                  required: {
                    value: true,
                    message: 'First Name is required',
                  },
                  pattern: {
                    value: /^[a-zA-Z ]+$/g,
                    message: 'Invalid Name. Avoid Special characters',
                  },
                })}
              />

              {fields.childfirstname && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={fields.childfirstname}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="text-field"
              {...register(`${fields.childfirstname}`)}
            />
          )}
        </label>
      </div>
      <div className="form-field">
        <label htmlFor="surname">
          Surname Name{' '}
          {fields.required === true ? <span className="required">*</span> : ''}
          {fields.required === true ? (
            <>
              <input
                type="text"
                className="text-field"
                {...register(`${fields.childsurname}`, {
                  required: {
                    value: true,
                    message: 'Sur Name is required',
                  },
                  pattern: {
                    value: /^[a-zA-Z ]+$/g,
                    message: 'Invalid Name. Avoid Special characters',
                  },
                })}
              />
              {fields.childsurname && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={fields.childsurname}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="text-field"
              {...register(`${fields.childsurname}`)}
            />
          )}
        </label>
      </div>
      <div className="form-field">
        <label htmlFor="dob">
          Date of Birth{' '}
          {fields.required === true ? <span className="required">*</span> : ''}
          {fields.required === true ? (
            <>
              <input
                type="date"
                className="text-field"
                {...register(`${fields.childdob}`, {
                  required: {
                    value: true,
                    message: 'Date of Birth is required',
                  },
                })}
              />
              {fields.childdob && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={fields.childdob}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="date"
              className="text-field"
              {...register(`${fields.childdob}`)}
            />
          )}
        </label>
      </div>
      <div className="form-field">
        <label htmlFor="schoolname">
          Name of School{' '}
          {fields.required === true ? <span className="required">*</span> : ''}
          {fields.required === true ? (
            <>
              <input
                type="text"
                className="text-field"
                {...register(`${fields.childnameofschool}`, {
                  required: {
                    value: true,
                    message: 'Name of School is required',
                  },
                })}
              />

              {fields.childnameofschool && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={fields.childnameofschool}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="text-field"
              {...register(`${fields.childnameofschool}`)}
            />
          )}
        </label>
      </div>
      <div className="form-field">
        <label htmlFor="grade">
          Grade{' '}
          {fields.required === true ? <span className="required">*</span> : ''}
          {fields.required === true ? (
            <>
              <input
                type="text"
                className="text-field"
                {...register(`${fields.childgrade}`, {
                  required: {
                    value: true,
                    message: 'Grade is required',
                  },
                })}
              />

              {fields.childgrade && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={fields.childgrade}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="text-field"
              {...register(`${fields.childgrade}`)}
            />
          )}
        </label>
      </div>
      <div className="form-field">
        <label htmlFor="baptisedat">
          Baptised at{' '}
          {fields.required === true ? <span className="required">*</span> : ''}
          {fields.required === true ? (
            <>
              <input
                type="text"
                className="text-field"
                {...register(`${fields.childbaptisedat}`, {
                  required: {
                    value: true,
                    message: 'Baptised at is required',
                  },
                })}
              />

              {fields.childbaptisedat && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={fields.childbaptisedat}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="text-field"
              {...register(`${fields.childbaptisedat}`)}
            />
          )}
        </label>
      </div>
      <div className="form-field">
        <label htmlFor="baptisedon">
          Baptised on{' '}
          {fields.required === true ? <span className="required">*</span> : ''}
          {fields.required === true ? (
            <>
              <input
                type="date"
                className="text-field"
                {...register(`${fields.childbaptisedon}`, {
                  required: {
                    value: true,
                    message: 'Baptised on is required',
                  },
                })}
              />

              {fields.childbaptisedon && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={fields.childbaptisedon}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="date"
              className="text-field"
              {...register(`${fields.childbaptisedon}`)}
            />
          )}
        </label>
      </div>
      {formContent && (
        <div className="page-content center form-content">
          <ReactMarkdown>{formContent}</ReactMarkdown>
        </div>
      )}
    </div>
  );
};

export { ReconciliationChildForm };
