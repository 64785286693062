import React, { FC } from 'react';
import {
  PageHeading,
  ContentList,
  Caption,
  FullWidthImage,
  OurStaff,
  MarriageSection,
  MeetingSchedule,
  StaffDetail,
  TextImage,
  BannerVideo,
  ImageVideoPDFSection,
  TitleAndSummary,
  RecordedMass,
  FormButton,
  ParishChecklist,
  BulletinFilter,
  ContactSection,
  ContactMap,
  HomePageSlider,
  FeaturedContent,
  PrayerQuote,
  BannerImageList,
  HomeReadingsCalendar,
  HomeArticles,
  ArticlesList,
} from 'components';
import ReactMarkdown from 'react-markdown';
import { useLocation } from '@reach/router';

export const AllDynamicComponent: FC = ({
  alldynamicComponents,
  formregister,
  formerrors,
}: any) => {
  const location = useLocation();
  return (
    <div>
      {alldynamicComponents.map((section: any) => {
        return (
          <React.Fragment key={section.id}>
            <div>
              {section?.internal?.type === 'STRAPI__COMPONENT_DYNAMIC_BANNER' &&
                section?.active && (
                  <BannerVideo image={section.image} video={section.video} />
                )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_CAPTION' &&
                section?.active && (
                  <Caption
                    title={section.title}
                    summary={section.summary}
                    bgColor={section?.backgroundColor}
                    contentAlignment={section.contentAlignment}
                  />
                )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_CONTENT_LIST' &&
                section?.active && (
                  <ContentList
                    title={section.title}
                    listSummary={section.listSummary}
                    bgColor={section?.backgroundColor}
                    contentAlignment={section.contentAlignment}
                  />
                )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_CONTENT_WITH_IMAGE' &&
                section?.active && (
                  <OurStaff
                    name={section.name}
                    image={section.image}
                    Heading={section.pageHeading.title}
                    subHeading={section.pageHeading.tagline}
                    active={section.pageHeading.active}
                    description={
                      section.pageHeading.description?.data?.description
                    }
                  />
                )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_FULL_WIDTH_IMAGE' && (
                <FullWidthImage image={section.image} />
              )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_IMAGE_VIDEO_COMPONENT' &&
                section?.active && (
                  <ImageVideoPDFSection
                    sectionData={section.videoImage}
                    title={section.title}
                    type={section.type}
                  />
                )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_MARRIGE' &&
                section?.active && (
                  <MarriageSection
                    weddingPhotographesTitle={section.weddingPhotographesTitle}
                    weddingPhotographesLink={section.weddingPhotographesLink}
                    weddingPhotos={section.weddingPhotos}
                  />
                )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_TITLE_AND_DESCRIPTION' && (
                <PageHeading
                  Heading={section.title}
                  subHeading={section.tagline}
                  content={section.description.data.description}
                  active={section.active}
                />
              )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_MEETING_SCHEDULE' &&
                section?.active && (
                  <MeetingSchedule
                    Heading={section.title}
                    dateTitle={section?.dateTitle}
                    timeTitle={section?.timeTitle}
                    bgColor={section?.backgroundColor}
                    content={section.description?.data?.description}
                    summary={section.summary?.data?.summary}
                    dateWithTime={section?.dateWithTime}
                  />
                )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_STAFF_MEMBER_AND_PARISH_DETAIL' &&
                section?.active && (
                  <StaffDetail
                    Heading={section.title}
                    description={section.description?.data?.description}
                    name={section.name}
                    phoneNo={section.phoneNo}
                    role={section.role}
                    mailId={section.mailId}
                    faxNo={section.faxNo}
                    image={section.image}
                    parishDetails={section.parishDetails}
                  />
                )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_TWO_COLUMN' &&
                section?.active && (
                  <>
                    <div className="heading-design">
                      <h3 className="section-title">
                        <ReactMarkdown>{section.title}</ReactMarkdown>
                      </h3>
                    </div>
                    <div className="two-column-section">
                      <TextImage twoColumnsList={section.twoColumnsList} />
                    </div>
                  </>
                )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_FEATURED_CONTENT' &&
                section?.active && (
                  <FeaturedContent featuredContentData={section} />
                )}
              {section?.internal?.type === 'STRAPI__COMPONENT_DYNAMIC_QUOTE' &&
                section?.active && <PrayerQuote quoteData={section} />}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_SLIDER' && (
                <HomePageSlider SliderData={section.slider} />
              )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_TITLE_AND_SUMMARY' &&
                section?.active && (
                  <TitleAndSummary
                    title={section.title}
                    summary={section.summary}
                  />
                )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_FILTERED_BYDATE_LIST' &&
                section?.active && (
                  <>
                    {section.type === 'bulletin' && <BulletinFilter />}
                    {section.type === 'mass-recording' && <RecordedMass />}
                  </>
                )}
              {section?.internal?.type === 'STRAPI__COMPONENT_DYNAMIC_FORM' &&
                section?.active && (
                  <div className="container">
                    <FormButton section={section} />
                  </div>
                )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_PARISH_FORM_CHECK_BOX' && (
                <ParishChecklist
                  section={section}
                  formregister={formregister}
                  formerrors={formerrors}
                />
              )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_CONTACT_FORM' &&
                section?.active && (
                  <ContactSection
                    title={section.title}
                    showContactDetails={section.showContactDetails}
                    description={section.description}
                    signUp={section.signUp}
                  />
                )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_CONTACT_MAP' &&
                section?.active && <ContactMap />}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_BANNER_IMAGE_LIST' &&
                section?.active && (
                  <div>
                    <BannerImageList
                      title={section.title}
                      image={section.image}
                      bannerListItem={section.bannerListItem}
                      description={section.description}
                    />
                  </div>
                )}
              {section?.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_HOME_READINGS_CALENDAR' && (
                <HomeReadingsCalendar />
              )}

              {section.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_BLOG_SECTION' &&
                !location.pathname.includes('/blogs') && (
                  <HomeArticles
                    blogSlider={section.blogSlider}
                    heading={section.title}
                    ctaTitle={section.ctaTitle}
                    ctaLink={section.ctaLink}
                  />
                )}
              {section.internal?.type ===
                'STRAPI__COMPONENT_DYNAMIC_BLOG_SECTION' &&
                location.pathname.includes('/blogs') && (
                  <ArticlesList
                    blogSlider={section.blogSlider}
                    heading={section.title}
                  />
                )}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
