import React from 'react';
import ReactMarkdown from 'react-markdown';

export const ContentList = ({
  listSummary,
  title,
  bgColor,
  contentAlignment,
}: any) => {
  return (
    <div className="contentlist">
      <div className={`${bgColor ? 'backgroundclr' : ''}`}>
        <div className="commonlist-section">
          {title && (
            <div className="heading-design">
              <h3 className="section-title">
                <ReactMarkdown>{title}</ReactMarkdown>
              </h3>
            </div>
          )}
          <div className="commonlist-wrapper">
            <ol>
              {listSummary &&
                listSummary.map((item: any) => {
                  return (
                    <div className="commonlist-list">
                      <li key={item.title}>
                        <ReactMarkdown>{item.title}</ReactMarkdown>
                      </li>
                      {item.summary && (
                        <div className={`page-content ${contentAlignment}`}>
                          <ReactMarkdown>
                            {item.summary.data.summary}
                          </ReactMarkdown>
                        </div>
                      )}
                    </div>
                  );
                })}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};
