import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import moment from 'moment';

const TodaysReading = ({ dailyMassReading }: any) => {
  const todaysDate = moment().format('yyyy-MM-DD');
  return (
    <div className="card-section">
      <div className="card-heading-wrapper">
        <h2 className="card-heading-date">
          <strong>Today's</strong> Reading
        </h2>
      </div>
      <div className="todaysreading-section-content">
        {dailyMassReading &&
          dailyMassReading.length > 0 &&
          dailyMassReading.map(item => {
            const { readingTitle, content, readingLink, date } = item.node;
            if (date === todaysDate)
              return (
                <React.Fragment key={date}>
                  <div className="todaysreading-section-content-tagline">
                    {readingLink ? (
                      <a
                        href={readingLink}
                        className="todaysreading-section-content-tagline-link"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {readingTitle}
                      </a>
                    ) : (
                      <span>{readingTitle}</span>
                    )}
                  </div>
                  <div className="todaysreading-section-content-summary">
                    <ReactMarkdown>{content.data.content}</ReactMarkdown>
                  </div>
                </React.Fragment>
              );
          })}
      </div>
    </div>
  );
};
export { TodaysReading };
