/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { PrevNextButton } from 'components';
import { getClosestDate, getScheduledEvents, mergeEvents } from 'utils';

const Calender = ({ calenderData, eventSize = 3 }: any) => {
  const resultData = mergeEvents(calenderData, getScheduledEvents());
  // sort Dates
  const sortedDates = resultData.sort((a: any, b: any) => {
    return new Date(a.date) - new Date(b.date);
  });

  const calendarLength = resultData.length;

  const closestIndex = getClosestDate(sortedDates);

  const [currentEvent, setCurrentEvent] = useState(
    Math.floor(closestIndex / eventSize)
  );

  const eventresult = useMemo(() => {
    const firstEventIndex = currentEvent * eventSize;
    const lastEventIndex = firstEventIndex + eventSize;
    return sortedDates.slice(firstEventIndex, lastEventIndex);
  }, [currentEvent, sortedDates]);

  const handleonEventClick = (event: any) => {
    setCurrentEvent(event);
  };

  return (
    <>
      <div className="card-section">
        <div className="card-heading-wrapper">
          <h2 className="card-heading">Calender</h2>
        </div>
        <div className="card-content">
          {eventresult.map((items: any) => {
            const { date, events, id } = items;
            const month = moment(date).format('MMM');
            const dayOfMonth = moment(date).format('D');
            const dayOfWeek = moment(date).format('ddd');
            return (
              <table className="calender-table" key={id}>
                <tbody className="calender-table-body">
                  {events.map((event: any, index: any) => {
                    const tableTH = index === 0 && (
                      <th
                        className="calender-table-date"
                        rowSpan={events.length}
                        key={event.id}
                      >
                        <span className="date-tile">
                          <span className="date-tile-month">{month}</span>
                          <span className="date-tile-date">{dayOfMonth}</span>
                          <span className="date-tile-day">{dayOfWeek}</span>
                        </span>
                      </th>
                    );
                    return (
                      <React.Fragment key={event.title}>
                        <tr className="">
                          {tableTH}
                          <td className="calender-table-time">{event.time}</td>
                          <td className="calender-table-title">
                            {event.title}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            );
          })}
        </div>
      </div>
      <PrevNextButton
        currentPage={currentEvent}
        totalCount={calendarLength}
        pageSize={eventSize}
        onPageChange={handleonEventClick}
      />
    </>
  );
};
export { Calender };
