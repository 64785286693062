import React from 'react';
import GoogleMapReact from 'google-map-react';
import { graphql, Link, useStaticQuery } from 'gatsby';

const mapStyle = () => {
  return {
    styles: [
      {
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd',
          },
        ],
      },
      {
        featureType: 'landscape.natural',
        stylers: [
          {
            color: '#e4e4e4',
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'poi.attraction',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dadada',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'transit.line',
        stylers: [
          {
            color: '#ffeb3b',
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry.stroke',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'labels.text.fill',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#c9c9c9',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
    ],
  };
};

const mapProp = {
  defaultCenter: { lat: -37.80785, lng: 145.03786 },
  defaultZoom: 10,
  styles: mapStyle,
  locations: [
    {
      locationName: 'Sacred Heart church',
      address: 'Kew',
      position: {
        lat: -37.80785,
        lng: 145.03786,
      },
    },
  ],
};

const ContactMap = ({
  defaultCenter = mapProp.defaultCenter,
  locations = mapProp.locations,
}: any) => {
  const contactMapQuery = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          googleMapAPI
        }
      }
    }
  `);
  const renderMarkers = (map: any, maps: any) => {
    locations.map(
      (location: any) =>
        new maps.Marker({
          position: {
            lat: location.position.lat,
            lng: location.position.lng,
          },
          map,
          title: location.address,
        })
    );
  };
  return (
    <div className="contact-section-map">
      <div className="map" style={{ height: '600px', width: '100%' }}>
        <GoogleMapReact
          defaultZoom={17}
          defaultCenter={defaultCenter}
          options={mapProp.styles}
          yesIWantToUseGoogleMapApiInternals
          resetBoundsOnResize
          onGoogleApiLoaded={({ map, maps }: any) => renderMarkers(map, maps)}
          bootstrapURLKeys={{
            key: contactMapQuery?.site?.siteMetadata?.googleMapAPI,
          }}
        />
        <Link
          target="_blank"
          className="direction-btn"
          to="https://www.google.com/maps/dir//Catholic+Church+of+the+Sacred+Heart+116+Cotham+Rd+Kew+VIC+3101+Australia/@-37.8079645,145.0378389,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x6ad643c523f1127b:0x700c3b47dde64456!2m2!1d145.0378599!2d-37.8078511"
        >
          Get Directions
        </Link>
      </div>
    </div>
  );
};

export { ContactMap };
