import React, { useState, useEffect } from 'react';
import Logo from 'assets/svg/shkew-logo.svg';
import { Menu, ModalComponent } from 'components';
import { MenuQuery } from 'queries/menu';
import { Link } from 'gatsby';
import { useScrollDirection } from 'hooks/useScrollDirection';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage } from 'gatsby-plugin-image';

export interface HeaderProps {
  siteTitle: string;
}

export const Header = ({ siteTitle = '' }) => {
  const menuItems = MenuQuery();
  const pagePopupData = menuItems.strapiGlobal.pagePopup;
  const image = pagePopupData.image?.localFile.childImageSharp.gatsbyImageData;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const scrollDirection = useScrollDirection();
  const headerNavClass = isMenuOpen ? 'active' : '';
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 150) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
    document.body.classList.toggle('menu-open', isMenuOpen);
  }, [isMenuOpen]);

  const [isModal, setisModal] = useState(true);
  const [displayPopUp, setDisplayPopUp] = useState(false);
  useEffect(() => {
    const seenPopUp = window.sessionStorage.getItem('seenPopUp');
    if (!seenPopUp) {
      setisModal(true);
      setDisplayPopUp(true);
    }
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem('seenPopUp', 'true');
  }, [setDisplayPopUp]);
  const closeModal = () => {
    setisModal(false);
  };
  return (
    <>
      {displayPopUp && pagePopupData.showOnPageLoad && (
        <ModalComponent
          isModal={isModal}
          closesModal={closeModal}
          className={`home-popup ${image ? 'home-popup-image' : ''}`}
        >
          <div className="alert-location" id="alert-location">
            <div className="alert-modal-content">
              <div className="alert-location-details">
                <h3 className="alert-location-details-title">
                  {pagePopupData.title}
                </h3>
                <div className="alert-location-details-message">
                  <ReactMarkdown>
                    {pagePopupData.description.data.description}
                  </ReactMarkdown>
                </div>
              </div>
              {image && (
                <div className="alert-location-details-image">
                  <GatsbyImage image={image} alt="" />
                </div>
              )}
            </div>
          </div>
        </ModalComponent>
      )}
      <header
        className={`header ${headerNavClass} ${
          scrollDirection === 'down' ? '' : 'sticky'
        } ${isMenuOpen ? 'sticky' : ''} ${scrolled ? 'scrolled' : ''}`}
      >
        <div className="header-bar">
          <div className="header-logo">
            <Link to="/">
              <Logo className="logo" />
            </Link>
          </div>
          <div className="header-right">
            <Menu toggleMenu={toggleMenu} setIsMenuOpen={setIsMenuOpen} />
          </div>
          <button
            type="button"
            className="hamburger-menu close-btn"
            onClick={toggleMenu}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
      </header>
    </>
  );
};
