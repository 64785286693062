/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ErrorMessage } from '@hookform/error-message';

const ReconciliationParentForm = ({ register, errors, fields }: any) => {
  return (
    <div className="form-accordion-item">
      <div className="form-field">
        <label htmlFor="name">
          First Name{' '}
          {fields.required === true ? <span className="required">*</span> : ''}
          {fields.required === true ? (
            <>
              <input
                type="text"
                className="text-field"
                {...register(`${fields.parentfirstname}`, {
                  required: {
                    value: true,
                    message: 'First Name is required',
                  },
                  pattern: {
                    value: /^[a-zA-Z ]+$/g,
                    message: 'Invalid Name. Avoid Special characters',
                  },
                })}
              />

              {fields.parentfirstname && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={fields.parentfirstname}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="text-field"
              {...register(`${fields.parentfirstname}`)}
            />
          )}
        </label>
      </div>
      <div className="form-field">
        <label htmlFor="surname">
          Surname Name
          {fields.required === true ? <span className="required">*</span> : ''}
          {fields.required === true ? (
            <>
              <input
                type="text"
                className="text-field"
                {...register(`${fields.parentsurname}`, {
                  required: {
                    value: true,
                    message: 'Sur Name is required',
                  },
                  pattern: {
                    value: /^[a-zA-Z ]+$/g,
                    message: 'Invalid Name. Avoid Special characters',
                  },
                })}
              />
              {fields.parentsurname && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={fields.parentsurname}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="text-field"
              {...register(`${fields.parentsurname}`)}
            />
          )}
        </label>
      </div>
      <div className="form-field">
        <label htmlFor="religion">
          Religion{' '}
          {fields.required === true ? <span className="required">*</span> : ''}
          {fields.required === true ? (
            <>
              <input
                type="text"
                className="text-field"
                {...register(`${fields.parentreligion}`, {
                  required: {
                    value: true,
                    message: 'Religion is required',
                  },
                })}
              />
              {fields.parentreligion && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={fields.parentreligion}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="text-field"
              {...register(`${fields.parentreligion}`)}
            />
          )}
        </label>
      </div>
      <div className="form-field">
        <label htmlFor="residentialAddress">
          Residential Address{' '}
          {fields.required === true ? <span className="required">*</span> : ''}
          {fields.required === true ? (
            <>
              <textarea
                className="text-field textarea"
                cols={30}
                rows={6}
                {...register(`${fields.parentresidentialAddress}`, {
                  required: {
                    value: true,
                    message: 'Address is required',
                  },
                })}
              />
              {fields.parentresidentialAddress && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={fields.parentresidentialAddress}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <textarea
              type="text"
              className="text-field textarea"
              cols={30}
              rows={6}
              {...register(`${fields.parentresidentialAddress}`)}
            />
          )}
        </label>
      </div>
      <div className="form-field">
        <label htmlFor="Landline">
          Landline{' '}
          {fields.required === true ? <span className="required">*</span> : ''}
          {fields.required === true ? (
            <>
              <input
                type="text"
                className="text-field"
                {...register(`${fields.parentlandline}`, {
                  required: {
                    value: true,
                    message: 'Number is required',
                  },
                })}
              />
              {fields.parentlandline && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={fields.parentlandline}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="text-field"
              {...register(`${fields.parentlandline}`)}
            />
          )}
        </label>
      </div>
      <div className="form-field">
        <label htmlFor="mobileno">
          Mobile{' '}
          {fields.required === true ? <span className="required">*</span> : ''}
          {fields.required === true ? (
            <>
              <input
                type="text"
                className="text-field"
                {...register(`${fields.parentmobile}`, {
                  required: {
                    value: true,
                    message: 'Number is required',
                  },
                  pattern: {
                    value: /^[0-9]{5,}$/g,
                    message: 'Please check the number',
                  },
                })}
              />
              {fields.parentmobile && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={fields.parentmobile}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="text-field"
              {...register(`${fields.parentmobile}`)}
            />
          )}
        </label>
      </div>
      <div className="form-field">
        <label htmlFor="email">
          Email{' '}
          {fields.required === true ? <span className="required">*</span> : ''}
          {fields.required === true ? (
            <>
              <input
                type="text"
                className="text-field"
                {...register(`${fields.parentemail}`, {
                  required: {
                    value: true,
                    message: 'Email is required',
                  },
                  pattern: {
                    value:
                      /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/g,
                    message: 'Invalid Email',
                  },
                })}
              />
              {fields.parentemail && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={fields.parentemail}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="text-field"
              {...register(`${fields.parentemail}`)}
            />
          )}
        </label>
      </div>
    </div>
  );
};

export { ReconciliationParentForm };
