import { FILTER_POST, STORE_POST } from 'Redux/actionTypes';

export const storePost = (data: any) => {
  return {
    type: STORE_POST,
    payload: data,
  };
};


export const filterPosts =
  (filterYear: any) => (dispatch: any, getState: any) => {
    const { FilterReducer } = getState();
    if (filterYear === 'All') {
      return dispatch({
        type: FILTER_POST,
        payload: FilterReducer.searchResults,
      });
    }
    const searchResults = FilterReducer.searchResults.filter((post: any) => {
      const postDate = new Date(post.node.date);
      const PostYear = postDate.getFullYear();
      if (filterYear === PostYear) {
        return post;
      }
    });
    return dispatch({ type: FILTER_POST, payload: searchResults });
  };
